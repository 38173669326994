import { useState } from "react";
import SavedWorkOrders from "./SavedWorkOrders";
import { FaRegFolderOpen, FaRegSave } from "react-icons/fa";
const Footer = ({ nextWindow, exitWindow, backWindow, stepState, btnDisabled = false, saveWO, setChosenWo, showSavedWorkOrders, setShowSavedWorkOrders }) => {

  const CancelWizard = async () => {
    if (window.confirm("Är du säker på att du vill avbryta?")) {
      exitWindow();
    }
  }

  const openSavedWorkOrders = async () => {
    setShowSavedWorkOrders(2);
  }

  const closeSavedWorkOrders = async (state) => {
    if (showSavedWorkOrders === 1 && state !== 1) {
      exitWindow();
    }
    setShowSavedWorkOrders(0);
  }

  return (
    <div className="divFooter">
      <button
        className={`btn btnNext`}
        disabled={btnDisabled === true}
        onClick={nextWindow}>
        Nästa
      </button>
      <button
        className={`btn btnNext`}
        disabled={stepState < 2}
        onClick={backWindow}>
        Tillbaka
      </button>
      <button
        className={`btn btnNext ${stepState === 1 ? "btnNotVisible" : ""}`}
        disabled={stepState < 2}
        onClick={saveWO}
        title="Spara påbörjad arbetsorder.">
        <FaRegSave />
      </button>
      <button
        className={`btn btnNext ${stepState !== 1 ? "btnNotVisible" : ""}`}
        disabled={stepState !== 1}
        onClick={openSavedWorkOrders}
        title="Öppna sparad arbetsorder.">
        <FaRegFolderOpen />
      </button>
      <button className="btn btnEnd" onClick={CancelWizard}>
        Avbryt
      </button>
      {
        showSavedWorkOrders === 1 || showSavedWorkOrders === 2 ? (<SavedWorkOrders closeSavedWorkOrders={closeSavedWorkOrders} setChosenWo={setChosenWo} />) : (<></>)
      }
    </div>
  );
};

export default Footer;
