import React from 'react'
import LabelExistingWo from '../controls/LabelExistingWo'
import TextBoxExistingWo from '../controls/TextBoxExistingWo';
import { useState, useEffect } from "react";
import { FaEdit } from "react-icons/fa";
import { Tooltip } from 'react-tooltip'
import waitonCreate from "../../../assets/load.gif";
import { Table } from 'react-bootstrap';

const TabEndCustomer = ({ woDetails, woUpdateData, setWoUpdateData, saveUpdate, setUpdateMsg, updateMsg, setIsUpdating, isUpdating, getParameterValue, user }) => {
    const [isEdit, setIsEdit] = useState(false);
    const [isErr, setIsErr] = useState(false);
    const [isErrPhone, setIsErrPhone] = useState(false);
    function validate() {
        let ret = false;
        if (!/^[0-9]{3}\s?[0-9]{2}$/.test(woUpdateData.customerPostalcode)) {
            setIsErr(true);
            ret = true;
        } else {
            setIsErr(false);
        }
        if (!/^\+?[0-9]*$/.test(woUpdateData.customerPhone)) {
            setIsErrPhone(true);
            ret = true;
        } else {
            setIsErrPhone(false);
        }
        return ret;
    }

    function dataIsNotChanged() {
        let ret = false;
        if (woDetails[0].ADDRESS1 === woUpdateData.customerName &&
            woDetails[0].ADDRESS2 === woUpdateData.customerAddress &&
            woDetails[0].ADDRESS3 === woUpdateData.customerPostalcode &&
            woDetails[0].ADDRESS4 === woUpdateData.customerCity &&
            getParameterValue("9996") === woUpdateData.customerPhone) {
            ret = true;
        }
        return ret;
    }

    const edit = (e) => {
        setIsEdit(!isEdit);
        setUpdateMsg({ ...updateMsg, isOk: false, message: "" });
    };

    useEffect(() => {
        console.log(woUpdateData);
        if (woUpdateData.test1 === "UPDATE_CUST") {
            saveUpdate();
            setWoUpdateData({
                ...woUpdateData, test1: ""
            })
        }
    }, [woUpdateData]);

    const saveWo = (e) => {
        console.log("Save");
        if (validate() === false) {
            setIsUpdating(true);
            setUpdateMsg({ ...updateMsg, isOk: false, message: "" });

            let date = new Date().toLocaleString() + "";
            let tmp = "Uppdatering av " + user + " - " + date + "\n" +
                "Namn: " + woUpdateData.customerName + "\n" +
                "Adress: " + woUpdateData.customerAddress + "\n" +
                "Post nr: " + woUpdateData.customerPostalcode + "\n" +
                "Ort: " + woUpdateData.customerCity + "\n" +
                "Tel: " + woUpdateData.customerPhone + "\n\n" +
                woDetails[0].WORK_DESCR_LO;
            setWoUpdateData({
                ...woUpdateData,
                longWorkDesc: tmp,
                test1: "UPDATE_CUST"
            })
        }
    };

    useEffect(() => {
        console.log(woUpdateData);
    }, [woUpdateData]);

    if (isEdit) {
        return (
            <>
                <Table responsive="lg" className="table1" borderless="true">
                    <tbody>
                        <tr>
                            <td colSpan={2}>
                                <FaEdit id="faediticon" data-tooltip-content="Ändra data" onClick={edit} className="faEdit" />
                                <Tooltip anchorId="faediticon" multiline={true} data-html={true} />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <TextBoxExistingWo headerText="Namn" text={woUpdateData} setText={setWoUpdateData} jsonText="customerName" maxLength="30" />
                            </td>
                            <td>
                                <TextBoxExistingWo headerText="Adress" text={woUpdateData} setText={setWoUpdateData} jsonText="customerAddress" maxLength="30" />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <TextBoxExistingWo headerText="Postnummer" text={woUpdateData} setText={setWoUpdateData} jsonText="customerPostalcode"
                                    maxLength="6" isErr={isErr} errText="Fem siffror. 000 00 eller 00000" />
                            </td>
                            <td>
                                <TextBoxExistingWo headerText="Ort" text={woUpdateData} setText={setWoUpdateData} jsonText="customerCity" maxLength="30" />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <TextBoxExistingWo headerText="Telefonnummer" text={woUpdateData} setText={setWoUpdateData} jsonText="customerPhone"
                                    maxLength="12" isErr={isErrPhone} errText="Giltliga tecken är + och 0-9" />
                            </td>
                            <td>
                                {" "}
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <button className="btn" onClick={saveWo} disabled={isUpdating || dataIsNotChanged()}>
                                    Spara
                                </button>
                                {" "}<div>{updateMsg.message}</div>
                                {
                                    isUpdating ? <img src={waitonCreate} alt="loading..." className="loadGifsmall" /> : <></>
                                }
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </>
        );
    } else {
        return (
            <>
                <Table responsive="lg" className="table1" borderless="true">
                    <tbody>
                        <tr>
                            <td colSpan={2}>
                                <FaEdit id="faediticon" data-tooltip-content="Ändra data" onClick={edit} className="faEdit" />
                                <Tooltip anchorId="faediticon" multiline={true} data-html={true} />
                            </td>
                        </tr>
                        <tr>
                            <td><LabelExistingWo text={woDetails[0].ADDRESS1} headerText="Namn" jsonText="name" /></td>
                            <td><LabelExistingWo text={woDetails[0].ADDRESS2} headerText="Adress" jsonText="address" /></td>
                        </tr>
                        <tr>
                            <td><LabelExistingWo text={woDetails[0].ADDRESS3} headerText="Postnummer" jsonText="postnumber" /></td>
                            <td><LabelExistingWo text={woDetails[0].ADDRESS4} headerText="Ort" jsonText="city" /></td>
                        </tr>
                        <tr>
                            <td><LabelExistingWo text={getParameterValue("9996")} headerText="Telefonnummer" jsonText="phonenumber" /></td>
                            <td>{" "}</td>
                        </tr>
                    </tbody>
                </Table>
            </>
        )
    }
}

export default TabEndCustomer