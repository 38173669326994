import SavedWorkOrders from "./SavedWorkOrders";
import { FaRegSave } from "react-icons/fa";

const FooterCreate = ({ exitWindow, backWindow, stepState, createWO, btnDisabled = false, saveWO, setChosenWo, showSavedWorkOrders, setShowSavedWorkOrders }) => {
    const CancelWizard = async () => {
        if (window.confirm("Är du säker på att du vill avbryta?")) {
            exitWindow();
        }
    }
    const openSavedWorkOrders = async () => {
        setShowSavedWorkOrders(2);
    }
    const closeSavedWorkOrders = async (state) => {
        setShowSavedWorkOrders(0);
    }
    return (
        <div className="divFooter">
            <button
                className={`btn btnNext ${btnDisabled ? "btnDisabeld" : ""}`}
                onClick={createWO}
                disabled={btnDisabled} >
                Skapa order
            </button>
            <button
                className={`btn btnNext ${btnDisabled ? "btnDisabeld" : ""}`}
                disabled={btnDisabled}
                onClick={backWindow}
            >
                Tillbaka
            </button>
            <button
                className={`btn btnNext ${stepState === 1 ? "btnNotVisible" : ""}`}
                disabled={stepState < 2}
                onClick={saveWO}
                title="Spara påbörjad arbetsorder.">
                <FaRegSave />
            </button>
            <button className="btn btnEnd" onClick={CancelWizard}>
                Avbryt
            </button>
            {
                showSavedWorkOrders ? (<SavedWorkOrders closeSavedWorkOrders={closeSavedWorkOrders} setChosenWo={setChosenWo} />) : (<></>)
            }
        </div>
    );
}

export default FooterCreate