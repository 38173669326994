import "./existingWO.css";

import { useState, useEffect } from "react";
import loadinggif from "../../../assets/load.gif";
//import Tabs from "../Tabs/Tabs";
import { loginRequest } from '../../../authConfig';
import { useMsal } from '@azure/msal-react';
import TabOverView from "./TabOverView";
import TabEndCustomer from "./TabEndCustomer";
import TabWorkDesc from "./TabWorkDesc";
import TabWorkDone from "./TabWorkDone";
import TabAttachments from "./TabAttachments";
import WoCanceled from "./WoCanceled";
import { Modal, Button, Tab, Tabs, Row, Col } from 'react-bootstrap';

const ExistingWOMain = ({ item, setShowWOinfo, showWIinfo, user = "", updateWoList }) => {
  const ApiDomain = process.env.REACT_APP_BACKEND_API_HOST;
  const [woDetails, setWoDetails] = useState([]);
  const [woDetailsError, setWoDetailsError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { instance, accounts } = useMsal();
  const [updateMsg, setUpdateMsg] = useState({ isOk: false, message: "" });
  const [isUpdating, setIsUpdating] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [cancelInProgress, setCancelInProgress] = useState(false);
  const [cancelStatus, setCancelStatus] = useState("");
  const [triggerGetFiles, setTriggerGetFiles] = useState(0);

  useEffect(() => {
    console.log(woDetails);
    if (woDetails.length > 0) {
      setWoUpdateData({
        ...woUpdateData,
        customerName: woDetails[0].ADDRESS1,
        customerAddress: woDetails[0].ADDRESS2,
        customerPostalcode: woDetails[0].ADDRESS3,
        customerCity: woDetails[0].ADDRESS4,
        customerPhone: getParameterValue("9996"),
        clientContact: woDetails[0].CONTACT,
        clientPhone: woDetails[0].PHONE_NO,
        wo_no: woDetails[0].WO_NO,
        referensnummer: woDetails[0].REFERENCE_NO,
        status: woDetails[0].OBJSTATE
      })
    }
  }, [woDetails]);

  const [woUpdateData, setWoUpdateData] = useState(
    {
      kommun: "",
      product: "",
      startTime: "",
      endTime: "",
      streetAddress: "",
      key: "",
      keyid: "",
      customerName: "",
      customerPhone: "",
      customerAddress: "",
      customerPostalcode: "",
      customerCity: "",
      customerKeyID: "",
      customerKeyAddress: "",
      customerKeyName: "",
      customerKeyPhone: "",
      referensnummer: "",
      clientContact: "",
      clientPhone: "",
      siteID: "",
      siteAddress: "",
      sitePostalcode: "",
      siteCity: "",
      siteFloor: "",
      siteRow: "",
      accessSiteKeyID: "",
      accessSiteAddress: "",
      accessSitePostalcode: "",
      accessSiteCity: "",
      accessSiteName: "",
      accessSitePhone: "",
      longWorkDesc: "",
      lineNo: "",
      kobject: "",
      department: "",
      contract_id: "",
      maint_org: "",
      work_type_id: "",
      customer_no: "",
      equipmentID: "",
      equipmentInterface: "",
      equipmentConnectionNumber: "",
      mtrlEquipment: "",
      sparePart1: "",
      sparePart2: "",
      sparePart3: "",
      mtrlReturnMtrl: "",
      infoMtrlReturnMtrl: "",
      siteExists: "",
      customerCompanyName: "",
      clientContactEmail: "",
      test1: "",
      test2: "",
      messageType: "UPDATE",
      cancelReason: ""
    }
  );

  function getParameterValue(id) {
    const parameterValue = woDetails[0].Parameters.find((p) => p.PARAMETER_ID === id);
    if (parameterValue) {
      return parameterValue.VALUE_WITH_LOV;
    } else {
      return "";
    }
  }

  const exitWindow = async () => {
    setShowWOinfo(false);
  };

  const saveUpdate = async () => {
    console.log("*** woUpdateData ***");
    console.log(woUpdateData);
    getInstance("UPDATE");
  };

  function getInstance(type) {
    console.log("getInstance type = " + type);
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        //callMsGraph(response.accessToken).then((response) => setGraphData(response));
        console.log(accounts[0]);
        if (type === "UPDATE") {
          myFetchUpdateWO(response);
        }
      })
      .catch((error) => {
        instance
          .acquireTokenPopup({
            ...loginRequest,
            account: accounts[0],
          })
          .then((response) => {
            //callMsGraph(response.accessToken).then((response) => setGraphData(response));
            if (type === "UPDATE") {
              myFetchUpdateWO(response);
            }
            console.log(error);
          });
      });
  }

  const myFetchUpdateWO = async (responseToken) => {
    console.log(`myFetchcreateWO`);
    var type = "";
    if (woUpdateData.messageType === "CANCEL") {
      type = "DELETE";
      setCancelInProgress(true);
      setCancelStatus("PENDING");
    } else {
      type = "PATCH";
    }
    setLoading(true);
    const headers = new Headers();
    const bearer = `Bearer ${responseToken.accessToken}`;
    headers.append("Authorization", bearer);
    headers.append("Content-Type", "application/json");
    const options = {
      method: type,
      headers: headers,
      body: JSON.stringify(woUpdateData)
    };
    console.log(`newWoData =  ${JSON.stringify(woUpdateData)}`);
    let url = `https://${ApiDomain}/ora/wo/${woDetails[0].WO_NO}`;
    console.log(`createWO URL: ${url}`);
    const response = await fetch(url, options);
    const data = await response.json();
    console.log(`MSG: ${data.Message}`);

    if (!response.ok) {
      setUpdateMsg({ ...updateMsg, isOk: false, message: "Kunde inte uppdatera arbetsorder, försök igen eller kontakta Eltel SPOC om problemet kvarstår!" })
    }
    else {
      if (data.IsOk) {
        console.log("Success!");

        if (woUpdateData.messageType === "CANCEL") {
          setCancelStatus("OK");
        }
        else {
          setUpdateMsg({ ...updateMsg, isOk: true, message: "Order uppdaterad!" })
        }
      } else {
        console.log("Error " + data.Error);

        if (woUpdateData.messageType === "CANCEL") {
          setCancelStatus("ERROR");
        }
        else {
          setUpdateMsg({ ...updateMsg, isOk: false, message: data.Message })
        }
      }
      setRefresh(true);
    }
    setIsUpdating(false);
  };

  useEffect(() => {
    const getWoDetails = async () => {
      setLoading(true);
      instance
        .acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        })
        .then((response) => {
          myFetch(response);

        })
        .catch((error) => {
          instance
            .acquireTokenPopup({
              ...loginRequest,
              account: accounts[0],
            })
            .then((response) => {
              myFetch(response);
              console.log(error);
            });
        });
    }
    if (refresh === true) {
      getWoDetails();
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    const getWoDetails = async () => {
      setLoading(true);
      /*let url = `https://swewoxapptest.eltelnetworks.se/webapi/customer-web-v2/Ora/ora/get/wo/details?wono=${item.WO_NO}`;
      console.log(`1 URL: ${url}`);
      const res = await fetch(url);
      const data = await res.json();
      setWoDetails(data);
      console.log(`getWoDetails lengt =  ${data.length}`);
      setLoading(false);*/

      instance
        .acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        })
        .then((response) => {
          //callMsGraph(response.accessToken).then((response) => setGraphData(response));
          myFetch(response);

        })
        .catch((error) => {
          instance
            .acquireTokenPopup({
              ...loginRequest,
              account: accounts[0],
            })
            .then((response) => {
              //callMsGraph(response.accessToken).then((response) => setGraphData(response));
              myFetch(response);
              console.log(error);
            });
        });
    }
    getWoDetails();
  }, [item.WO_NO]);

  function myFetch(response) {
    const headers = new Headers();
    const bearer = `Bearer ${response.accessToken}`;
    headers.append("Authorization", bearer);
    const options = {
      method: "GET",
      headers: headers
    };
    let url = "";
    url = `https://${ApiDomain}/Ora/ora/get/wo/details?wono=${item.WO_NO}`;
    const res = fetch(url, options).then(function (response) {
      if (!response.ok) {
        setWoDetailsError(true);
      }

      return response.json();
    }).then((data) => {
      console.log(data);
      setWoDetails(data);
      setLoading(false);
    }
    ).catch(error => console.log(error));
  }

  const tabSelected = async (key) => {
    if (key === 'attachments')
    {
      setTriggerGetFiles(triggerGetFiles + 1);
    }
  };

  return (
    <Modal show={showWIinfo} size="lg" fullscreen={"lg-down"} onHide={() => setShowWOinfo(false)} className="modalDialog">
      <Modal.Header className="divExtHeader">
        <div >
          <label className="labelExtStep">{woDetails.length === 1 ? woDetails[0].ERR_DESCR : ""}</label>
          <span className="spanExtStep">{item.WO_NO}</span>
        </div>
      </Modal.Header>
      <Modal.Body className="divExtMiddle modalpc">
        {woDetailsError ? (
          <div className="errorMessage">
            Det går inte att visa detaljer för arbetsordern just nu, ladda om sidan för att försöka igen eller kontakta Eltel SPOC om problemet kvarstår.
          </div>
        ) :
          woDetails.length !== 1 ? (
            <div className="divSpinnerCenter">
              <img src={loadinggif} alt="loading..." className="loadGif loadGiflist loadgif" />
            </div>
          ) : (
            <Row className={"justify-content-center"}>
              <Col className={""}>
                <Tabs className="mb-3" onSelect={tabSelected} fill>
                  <Tab eventKey="details" title="ÖVERSIKT" tabClassName="tabCenter justify-content-center" fill>
                    <TabOverView woDetails={woDetails} woUpdateData={woUpdateData} setWoUpdateData={setWoUpdateData}
                      saveUpdate={saveUpdate} user={user} setUpdateMsg={setUpdateMsg} updateMsg={updateMsg} setIsUpdating={setIsUpdating} isUpdating={isUpdating}
                      getParameterValue={getParameterValue} />
                  </Tab>
                  <Tab eventKey="endcust" title="SLUTKUND" className="tabCenter justify-content-center">
                    <TabEndCustomer woDetails={woDetails} woUpdateData={woUpdateData} setWoUpdateData={setWoUpdateData}
                      saveUpdate={saveUpdate} setUpdateMsg={setUpdateMsg} updateMsg={updateMsg} setIsUpdating={setIsUpdating} isUpdating={isUpdating}
                      getParameterValue={getParameterValue} user={user} />
                  </Tab>
                  <Tab eventKey="workdesc" title="ARBETSBESKRIVNING">
                    <TabWorkDesc woDetails={woDetails} woUpdateData={woUpdateData} setWoUpdateData={setWoUpdateData}
                      saveUpdate={saveUpdate} user={user} setUpdateMsg={setUpdateMsg} updateMsg={updateMsg} setIsUpdating={setIsUpdating} isUpdating={isUpdating} />
                  </Tab>
                  <Tab eventKey="workdetails" title="UTFÖRT ARBETE">
                    <TabWorkDone woDetails={woDetails} />
                  </Tab>
                  <Tab eventKey="attachments" title="BILAGOR">
                    <TabAttachments wono={woDetails[0].WO_NO} triggerGetFiles={triggerGetFiles} setTriggerGetFiles={setTriggerGetFiles} />
                  </Tab>
                </Tabs>
              </Col>
            </Row>
          )}
      </Modal.Body>
      <Modal.Footer className="divExtFooter">
        <Button className="btn btnEnd" onClick={exitWindow}>
          Stäng
        </Button>
        {cancelInProgress ? (
          <WoCanceled woCanceledStatus={cancelStatus} setCancelInProgress={setCancelInProgress} updateWoList={updateWoList} exitWindow={exitWindow} />
        ) : (<></>)}
      </Modal.Footer>
    </Modal>

  );
};

export default ExistingWOMain;
