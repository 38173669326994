import { useState, useEffect } from 'react'
import Select from 'react-select';
import { Tooltip } from 'react-tooltip'
import { FaInfoCircle } from "react-icons/fa";
import { components } from 'react-select';
import "./DropDownSearchLeft.css"

import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../../authConfig';

const colourStyles = {
  control: (styles) => ({ ...styles, backgroundColor: '#0E193A', border: 0, boxShadow: 'none' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? '#0E193A'
        : isFocused
        ? '#0a58ca'
        : '#0E193A',
      color: isDisabled
        ? '#ccc'
        : isSelected
        ? '#fff'          
        : '#fff',
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled
          ? isSelected
            ? 'blue'
            : 'blue'
          : undefined,
      },
    };
  },
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: '#0E193A',
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: '#fff'
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: 'grey',
    ':hover': {
      backgroundColor: 'grey',
      color: 'white',
    },
  }),
  menu: (styles) => ({
    ...styles,
    border: '1px solid grey',
    backgroundColor: '#0E193A',
  })
};

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const options = [
    { value: "'WORKREQUEST','FAULTREPORT','OBSERVED'", label: 'Mottagen' },
    { value: "'PREPARED','UNDERPREPARATION'", label: 'Under planering' },
    { value: "'STARTED'", label: 'Pågående' },
    { value: "'KUNDKLAR','WORKDONE'", label: 'Kundklar' },
    { value: "'REPORTED'", label: 'Faktureras' },
    { value: "'RELEASED'", label: 'Väntande' },
  ];

const DropDownSearchLeft = ({ status, setStatus, workOrderFilter, updateWoList }) => {
    const { instance, accounts } = useMsal();
    const [selectedOption, setSelectedOption] = useState('');
    instance.setActiveAccount(accounts[0]);
    const ApiDomain = process.env.REACT_APP_BACKEND_API_HOST;

    useEffect(() => {
      if (workOrderFilter !== undefined)
      {
        if (workOrderFilter.FilterValues.length === 0)
        {
          updateWoList();
          return;
        }
        
        const initial = options.filter((opt) => {
          return workOrderFilter.FilterValues.find(obj => obj === opt.value) !== undefined;
        });
        let selectedValues = workOrderFilter.FilterValues.map((s) => `${s}`).join(',');
        setStatus(selectedValues);
        setSelectedOption(initial);
      }
    }, [setSelectedOption, setStatus, workOrderFilter]);

    const callSaveWorkOrderFilterApi = async (selectedValues) => {
      instance
          .acquireTokenSilent({
              ...loginRequest,
              account: accounts[0],
          })
          .then((response) => {
            saveWorkOrderFilter(response.accessToken, selectedValues);
          })
          .catch((error) => {
              instance
                  .acquireTokenPopup({
                      ...loginRequest,
                      account: accounts[0],
                  })
                  .then((response) => {
                    saveWorkOrderFilter(response.accessToken, selectedValues);
                      console.log(error);
                  });
          });
    }

    const saveWorkOrderFilter = (token, selectedValues) => {
      const workOrderFilter = {
          FilterValues: selectedValues.map((s) => `${s.value}`)
      };

      const headers = new Headers();
            const bearer = `Bearer ${token}`;
            headers.append("Authorization", bearer);
            headers.append('Content-Type', 'application/json');

            const requestOptions = {
                method: "POST",
                headers: headers,
                body: JSON.stringify(workOrderFilter),
            };
            let url = `https://${ApiDomain}/UserSettings/users/workorderfilter`;
            fetch(url, requestOptions).then((response) => {
                console.log('work order filter saved');
            }
            ).catch(error => console.log(error));
    }

    function handleChange(selected) {
        let selectedValues = selected.map((s) => `${s.value}`).join(',');
        setStatus(selectedValues);
        setSelectedOption(selected);
        callSaveWorkOrderFilterApi(selected);
        console.log(`value =  ${selectedValues}`);
    }
    return (
        <>
            <Select
                placeholder=" -- Välj status -- "
                name="ddseek"
                className="react-select-search-left"
                onChange={handleChange}
                options={options}
                isMulti={true}
                styles={colourStyles}
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                controlShouldRenderValue={true}
                isClearable={false}
                isSearchable={false}
                components={{
                  Option,
                  IndicatorSeparator: () => null
                }}
                value={selectedOption}
            />
            {" "}
            <FaInfoCircle id="ddinfo" className="infoCircelLeft" />
            <Tooltip anchorId="ddinfo" data-html={true} place="right" className='react-tooltip' style={{
                display: 'flex', flexDirection: 'column', zIndex: "10",
                position: 'absolute'
            }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span><b>Mottagen:</b> Order är mottagen och kommer snart att delas ut till en projektör eller tekniker</span>
                    <span><b>Under Planering:</b> Order är under planering utav projektör eller tekniker</span>
                    <span><b>Pågående:</b> Order utförs</span>
                    <span><b>Kundklar:</b> Order är utförd</span>
                    <span><b>Faktureras:</b> Order faktureras av Eltel</span>
                    <span><b>Väntande:</b> Eltel väntar på kundåterkoppling</span>
                </div>
            </Tooltip>
        </>
    )
}

export default DropDownSearchLeft