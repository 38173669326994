import TextBoxT1 from "../controls/TextBoxT1";
import RadioButtonR1 from "../controls/RadioButtonR1";

const Step4 = ({ setText, text, disabled = false, validateIsErr }) => {
    return (
        <>
            {" "}
            <div class="div-table">
                <div class="div-table-row">
                    <div class="div-table-col-header">
                        <h3 className="stepH3">Site detaljer</h3>
                    </div>
                </div>
                <div class="div-table-row">
                    <div class="div-table-col-header">
                        <RadioButtonR1 headerText="Finns site" jsonText="siteExists" setText={setText} text={text}
                            toolTip="Finns site info måste den fyllas i." errText="Välj om site finns eller ej." validateIsErr={validateIsErr} />
                    </div>
                </div>
                <div class="div-table-row">
                    <div class="div-table-col">
                        <TextBoxT1 headerText="Site ID" jsonText="siteID" setText={setText} text={text} disabled={disabled}
                            errText="Obligatorisk" validateIsErr={validateIsErr} maxLength="50"
                            toolTip="Namnet på den station/nod eller mobilsite som avses." />
                    </div>
                    <div class="div-table-col">
                        <TextBoxT1 headerText="Adress" jsonText="siteAddress" setText={setText} text={text} disabled={disabled}
                            errText="Obligatorisk" validateIsErr={validateIsErr} maxLength="50" />
                    </div>
                </div>
                <div class="div-table-row">
                    <div class="div-table-col">
                        <TextBoxT1 headerText="Postnummer" jsonText="sitePostalcode" setText={setText} text={text} disabled={disabled}
                            errText="Obligatorisk, fem siffror" validateIsErr={validateIsErr} maxLength="6" />
                    </div>
                    <div class="div-table-col">
                        <TextBoxT1 headerText="Ort" jsonText="siteCity" setText={setText} text={text} disabled={disabled}
                            errText="Obligatorisk" validateIsErr={validateIsErr} maxLength="30" />
                    </div>
                </div>
                <div class="div-table-row">
                    <div class="div-table-col">
                        <TextBoxT1 headerText="Våning" jsonText="siteFloor" setText={setText} text={text}
                            inputType="number" disabled={disabled} errText="Obligatorisk" validateIsErr={validateIsErr} maxLength="50" />
                    </div>
                    <div class="div-table-col">
                        <TextBoxT1 headerText="Rad/Stativ" jsonText="siteRow" setText={setText} text={text} disabled={disabled}
                            errText="Obligatorisk" validateIsErr={validateIsErr} maxLength="50" />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Step4