import React from 'react'
import User from './User'
import { useEffect, useState } from "react";
import CustomerDropDownSelector from "../controls/CustomerDropDownSelector";
import AgreementDropDownSelector from "../controls/AgreementDropDownSelector";

const Users = ({ users, setUsers, saveData, deleteData }) => {
    const [usersTmp, setUsersTmp] = useState([]);
    const [customerId, setCustomerId] = useState("NA");
    const [agreementId, setAgreementId] = useState("NA");
    let oldUser = "";


    /* useEffect(() => {
         const fixList = async () => {
             users.map((u, i) => {
                 if (u.UserName === oldUser) {
                     setUsersTmp([...usersTmp, { ...u, isSame: true }])
                 } else {
                     setUsersTmp([...usersTmp, { ...u, isSame: false }])
                 }
                 oldUser = u.UserName;
                 console.log(usersTmp);
             })
         }
         fixList();
     }, [users]);*/

    return (
        <div className='divUsers'>
            <div class="div-table">
                <div class="div-table-row">
                    <div class="div-table-col"><CustomerDropDownSelector headerText="Kund" ddData={users} customerId={customerId} setCustomerId={setCustomerId}  /></div>
                    <div class="div-table-col"><AgreementDropDownSelector headerText="Avtal" ddData={users} agreementId={agreementId} setAgreementId={setAgreementId}  /></div>
                </div>
            </div>
            {
                users.length > 0 ? (
                    users.map((user, index) => (
                        (customerId === "NA" || user.CustomerNo === customerId) && (agreementId === "NA" || user.Agreements === agreementId) ?
                        <>
                            {user.UserName === oldUser ? (
                                <>
                                    <User key={index} user={user} sameAsBefore={true} saveData={saveData} deleteData={deleteData} />
                                    <div className='divHide'>
                                        {oldUser = user.UserName}
                                    </div>
                                </>
                            ) : (
                                <>
                                    <User key={index} user={user} sameAsBefore={false} saveData={saveData} deleteData={deleteData} />
                                    <div className='divHide'>
                                        {oldUser = user.UserName}
                                    </div>
                                </>
                            )}

                        </> : <></>
                    ))
                ) : (<>Användare saknas</>)
            }
        </div>
    )
}

export default Users