import TextArea from "../controls/TextArea";
import AddAttachment from "./AddAttachment";
import ShowAttachements from "./ShowAttachements";

const Step8 = ({ setText, text, files, setFiles }) => {

    const deleteFile = (file) => {
        console.log("i=");
        //console.log(file);
        const tmpFile = [];
        let i = 0;
        Array.from(files).forEach(f => {
            if (f.name !== file.name) {
                console.log(f.name);
                tmpFile[i] = f;
                i++;
            }
        });
        console.log(tmpFile);
        setFiles(tmpFile);
        //setFiles(f.filter(item => item.name !== file.name));
    };

    return (
        <>
            {" "}
            <div class="div-table">
                <div class="div-table-row">
                    <div class="div-table-col-header">
                        <h3 className="stepH3">Arbetsbeskrivning</h3>
                    </div>
                </div>
                <div class="div-table-row">
                    <div class="div-table-col-header">
                        <TextArea setText={setText} text={text} headerText="Arbetsbeskrivning" jsonText="longWorkDesc"
                            tooltipText="Vad är det som är fel? Beskriv så tydligt du kan.<br />Ange även åtgärd som önskas. Skicka gärna med en eller flera bilder (nedan). " />
                    </div>
                </div>
                <div class="div-table-row">
                    <div class="div-table-col-header">
                        <AddAttachment files={files} setFiles={setFiles} />
                    </div>
                </div>
                {
                    files.length > 0 ? (
                        <div class="div-table-row">
                            <div class="div-table-col-header">
                                {
                                    Array.from(files).map((file, index) =>
                                        <ShowAttachements file={file} key={index} deleteFile={deleteFile} />
                                    )
                                }
                            </div>
                        </div>
                    ) : (<></>)
                }
            </div>
        </>
    );
}

export default Step8