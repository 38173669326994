import TextBoxT1 from "../controls/TextBoxT1";

const Step6 = ({ setText, text, validateIsErr }) => {
    return (
        <>
            {" "}
            <div class="div-table">
                <div class="div-table-row">
                    <div class="div-table-col-header">
                        <h3 className="stepH3">Utrustning / avlämningspunkt</h3>
                    </div>
                </div>
                <div class="div-table-row">
                    <div class="div-table-col">
                        <TextBoxT1 headerText="Utrustnings ID" jsonText="equipmentID" setText={setText} text={text}
                            errText="" validateIsErr={validateIsErr} maxLength="50" />
                    </div>
                    <div class="div-table-col">
                        <TextBoxT1 headerText="Interface" jsonText="equipmentInterface" setText={setText} text={text}
                            errText="" validateIsErr={validateIsErr} maxLength="50"
                            toolTip="Namn på den port i utrustning som avses." />
                    </div>
                </div>
                <div class="div-table-row">
                    <div class="div-table-col">
                        <TextBoxT1 headerText="Förbindelsenummer" jsonText="equipmentConnectionNumber" setText={setText} text={text}
                            errText="" validateIsErr={validateIsErr} maxLength="50"
                            toolTip="Id på uppkopplingen mot kund." />
                    </div>
                    <div class="div-table-col">
                        &nbsp;
                    </div>
                </div>
            </div>
        </>
    )
}

export default Step6