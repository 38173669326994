import WorkOrderItem from "./WorkOrderItem";
import "./wo.css";
import loading from "../../../assets/load.gif";
import { useEffect } from "react";
const WorkOrderItems = ({ ShowWoinfo, customer_no, workOrderListData, setWorkOrderListData }) => {

  const wosData = [
    {
      wono: 21548798,
      shortDesc: "CTT5BD-Fixed / LAN/Service Agreement",
      state: "Beredd",
      workType: "S_IPD",
      address2: "Gatan 3 32541 Stockholm",
      regdate: "2022-12-18 14:26",
    },
    {
      wono: 21256341,
      shortDesc: "G/BN FLS1E Linjenät Fiber Göteborg",
      state: "Aktiv",
      workType: "SEE_ID",
      address2: "Storgatan 66 32541 Flen",
      regdate: "2022-12-20 11:56",
    },
    {
      wono: 21252334,
      shortDesc: "VAÄ FV1E Linjenät Fiber Västra Ämtervik",
      state: "Kundklar",
      workType: "SEE_IPD",
      address2: "Sundbyvägen 70 41 Kalmar",
      regdate: "2022-11-20 11:56",
    },
    {
      wono: 2252334,
      shortDesc: "FB 30172872 FST40 Telia Wholesale Fiber Karlstad",
      state: "Aktiv",
      workType: "SEE_IPD",
      address2: "Byvädersgatan 35 Kalmar",
      regdate: "2022-11-20 11:56",
    },
    {
      wono: 22254334,
      shortDesc: "PLAN EXP TEST! Kävlinge C25 TP-nod, Ledsagning 2022-11-30",
      state: "Beredd",
      workType: "SEE_IPD",
      address2: "Storgatan 89B 325 41 Kalmar",
      regdate: "2022-11-20 11:56",
    },
    {
      wono: 21755634,
      shortDesc: "CTT10BD-Mobile / Mobile Core",
      state: "Kundklar",
      workType: "SEE_IPD",
      address2: "Köpsvängen 28 325 41 Kalmar",
      regdate: "2023-01-01 23:56",
    },
  ];

  /*useEffect(() => {
    const getWorkOrderList = async () => {
      setWorkOrderListData([]);
      let url = `https://swewoxapptest.eltelnetworks.se/webapi/customer-web-v2/Ora/ora/get/wo/list?customer_no=${customer_no}`;
      console.log(`1 URL: ${url}`);
      const res = await fetch(url);
      const data = await res.json();
      setWorkOrderListData(data);
      console.log(`getWorkOrderList lengt =  ${data.length}`);
    }
    getWorkOrderList();
  }, []);*/

  return (
    <div className="divTable">
      {
        workOrderListData.length !== 0 ? (
          workOrderListData.map((woData, index) => (
            <WorkOrderItem key={index} WOItem={woData} ShowWoinfo={ShowWoinfo} />
          ))
        ) :
          (<img src={loading} alt="loading..." className="loadGif loadGiflist" />)
      }
    </div>
  );
};

export default WorkOrderItems;
