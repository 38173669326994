import loadinggif from "../../../assets/load.gif";
import { loginRequest } from '../../../authConfig';
import { useMsal } from '@azure/msal-react';
import { useState } from "react";

const Attachment = ({ attachments }) => {
    const ApiDomain = process.env.REACT_APP_BACKEND_API_HOST;
    const { instance, accounts } = useMsal();
    const [isLoading, setIsLoading] = useState(false);
    const viewDoc = (e) => {
        console.log("viewDoc");
        getInstance();
    };
    const getInstance = async () => {
        setIsLoading(true);
        instance
            .acquireTokenSilent({
                ...loginRequest,
                account: accounts[0],
            })
            .then((response) => {
                getFile(response);
            })
            .catch((error) => {
                instance
                    .acquireTokenPopup({
                        ...loginRequest,
                        account: accounts[0],
                    })
                    .then((response) => {
                        getFile(response);
                        console.log(error);
                    });
            });
    }

    async function getFile(response) {
        const headers = new Headers();
        const bearer = `Bearer ${response.accessToken}`;
        headers.append("Authorization", bearer);
        const options = {
            method: "GET",
            headers: headers
        };
        let url = `https://${ApiDomain}/ora/get/attachment?wono=${attachments.WO_NO}&docNo=${attachments.DOC_NO}`;
        console.log(`1 URL: ${url}`);
        fetch(url, options)
            .then((response) => response.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${attachments.FILETITLE}${attachments.FILETYPE.toLowerCase()}`);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                setIsLoading(false);
            })
        console.log("END");
    }

    return (
        <div className="divFileContainer">
            <div className="divFileName">{attachments.FILETITLE}{attachments.FILETYPE.toLowerCase()}</div>
            <div className="divFileButton">
                <button className="btn" onClick={viewDoc} >
                    Visa
                </button>
            </div>
        </div>
    )
}

export default Attachment