export function statusMappings(objState, kundklar, kundmak) {
    let ret = "";

    if (kundmak === "Cancel" || kundmak == "Makulerad") {
        ret = "Makulerad";
    }
    else if (objState === "FAULTREPORT" || objState === "OBSERVED" || objState === "WORKREQUEST") {
        ret = "Mottagen";
    } else if (objState === "PREPARED" || objState === "UNDERPREPARATION") {
        ret = "Under planering";
    } else if (objState === "STARTED") {
        if (kundklar === null || kundklar === "") {
            ret = "Pågående";
        } else {
            ret = "Kundklar";
        }
    } else if (objState === "WORKDONE") {
        ret = "Kundklar";
    } else if (objState === "REPORTED") {
        ret = "Faktureras";
    } else if (objState === "RELEASED") {
        ret = "Väntande";
    } else {
        ret = "Okänd";
    }
    return ret;
}

export function colorMappings(objState, kundklar, kundmak) {
    let ret = "";

    if (kundmak === "Cancel" || kundmak == "Makulerad") {
        ret = "color7";
    }
    else if (objState === "FAULTREPORT" || objState === "OBSERVED" || objState === "WORKREQUEST") {
        ret = "color1";
    } else if (objState === "PREPARED" || objState === "UNDERPREPARATION") {
        ret = "color2";
    } else if (objState === "STARTED") {
        if (kundklar === null || kundklar === "") {
            ret = "color3";
        } else {
            ret = "color4";
        }
    } else if (objState === "WORKDONE") {
        ret = "color4";
    } else if (objState === "REPORTED") {
        ret = "color5";
    } else if (objState === "RELEASED") {
        ret = "color6";
    } else {
        ret = "";
    }
    return ret;
}

export function fixDateFormat(date) {
    var ret = "";
    if (date !== null) {
        if (date.length >= 16) {
            ret = date.replace("T", " ").substring(0, 16);
        } else {
            ret = date;
        }
    }
    return ret;
}

export function validate(newWoData, err) {
    console.log("*** newWoData ***");
    console.log(newWoData);
    console.log("*** err ***");
    console.log(err);
    return true;
}

function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
}

export function formatDate(date) {
    return (
        [
            date.getFullYear(),
            padTo2Digits(date.getMonth() + 1),
            padTo2Digits(date.getDate()),
        ].join('-') +
        ' ' +
        [
            padTo2Digits(date.getHours()),
            padTo2Digits(date.getMinutes()),
        ].join(':')
    );
}