import Select from "react-select"
import { FaInfoCircle } from "react-icons/fa";
import { Tooltip } from 'react-tooltip';
import "./dropDown.css";

const DropDownSearch = ({ headerText, ddData, setText, text, jsonText, remValKommun, setRemValKommun, errText = "", validateIsErr, tooltipText = "" }) => {

    function handleChange(evt) {
        if (evt.value !== "NA") {
            setRemValKommun(evt.value);
            //var obj = JSON.parse(evt.target.value);
            setText({ ...text, kobject: evt.value, kommun: evt.label });
            console.log(`value1 =  ${evt.value}`);
            console.log(`label =  ${evt.label}`);
            console.log(`name =  ${evt.name}`);
        } else {
            setRemValKommun("NA");
            setText({ ...text, kobject: "", kommun: "" });
        }
    }

    const styles = {
        control: (base) => ({
            ...base,
            backgroundColor: "#D8D9DB",
            borderColor: "red",
            border: validateIsErr[jsonText] === true ? "" : "none",
            borderRadius: "10px",
        }),
        singleValue: (base) => ({
            ...base,
            padding: "5px 10px",
            borderRadius: 5,
            display: "flex",
        }),
        menuList: (base) => ({
            ...base,
            maxHeight: "230px"
        })
    };

    return (
        <>
            <h6 className="txtH6dd">{headerText}
                {" "}
                <FaInfoCircle id={`info-circel-${jsonText}`} className="infoCircelTop" data-tooltip-html={tooltipText} display={tooltipText.length === 0 ? ("none") : ("")} />
                <Tooltip anchorId={`info-circel-${jsonText}`} className="infoCircelTop2" multiline={true} data-html={true} html />
            </h6>
            <Select onChange={handleChange} className="reactSelect reactSelectObject" name={jsonText} isSearchable={true}
                value={{ value: text.kobject, label: text.kommun === "" ? ("Välj kommun...") : (text.kommun) }}
                options={ddData.map(({ mch_object, kommun }) => ({ value: mch_object, label: kommun }))}
                styles={styles}>
            </Select>
            <br />
            <small className="errSmalldd">{validateIsErr[jsonText] === true ? errText : ""}</small>
        </>
    )
}

export default DropDownSearch