import { useState, useEffect } from 'react'
import { FileUploader } from "react-drag-drop-files";
import { loginRequest } from '../../../authConfig';
import { useMsal } from '@azure/msal-react';
import loadinggif from "../../../assets/load.gif";

const AddAttachment = ({ files, setFiles }) => {
    const ApiDomain = process.env.REACT_APP_BACKEND_API_HOST;
    const { instance, accounts } = useMsal();
    const [allowedFileExtensions, setAllowedFileExtensions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const changeHandler = (file) => {
        //console.log(`Fil =  ${file}`);

        const tmpFile = [];
        let i = 0;
        Array.from(files).forEach(f => {
            console.log(f.name);
            tmpFile[i] = f;
            i++;
        });
        Array.from(file).forEach(f => {
            console.log(f.name);
            if (f.size > 31457280) {
                alert(f.name + " är för stor, max 30 MB.\n Denna är " + Math.round(f.size / 1048576) + " MB")
            } else {
                tmpFile[i] = f;
                i++;
            }
        });
        setFiles(tmpFile);
    };

    useEffect(() => {
        const getInstance = async () => {
            setIsLoading(true);
            instance
                .acquireTokenSilent({
                    ...loginRequest,
                    account: accounts[0],
                })
                .then((response) => {
                    fetchAllowedFileExtensions(response);
                })
                .catch((error) => {
                    instance
                        .acquireTokenPopup({
                            ...loginRequest,
                            account: accounts[0],
                        })
                        .then((response) => {
                            fetchAllowedFileExtensions(response);
                            console.log(error);
                        });
                });
        }

        function fetchAllowedFileExtensions(response) {
            const headers = new Headers();
            const bearer = `Bearer ${response.accessToken}`;
            headers.append("Authorization", bearer);
            const options = {
                method: "GET",
                headers: headers
            };
            let url = "";
            url = `https://${ApiDomain}/fileextensions`;
            fetch(url, options).then((response) => response.json()).then((data) => {
                console.log(data);
                setAllowedFileExtensions(data);
                setIsLoading(false);
            }
            ).catch(error => console.log(error));
        }

        getInstance();
    }, []);

    return (
        <div>
            {
                isLoading ? (<img src={loadinggif} alt="loading..." className="loadGif loadGiflist" />) : (
                    <FileUploader
                        handleChange={changeHandler}
                        name="file"
                        multiple={true}
                        label="Ladda upp eller droppa fil här."
                        hoverTitle="Lägg till fil här!"
                        types={allowedFileExtensions} >
                    </FileUploader>
                )
            }

        </div>
    )
}

export default AddAttachment