import Select from "react-select"
import { FaInfoCircle } from "react-icons/fa";
import { Tooltip } from 'react-tooltip'

const DropDownSearchD2 = ({ headerText, ddData, setText, text, jsonText, remValLine, setRemValLine, sla, setSla, errText = "", validateIsErr, tooltipText = "", setIsBack }) => {
    function handleChange(evt) {
        setIsBack(false);
        setRemValLine(evt.value);
        var obj = JSON.parse(evt.value);
        setText({
            ...text,
            lineNo: obj.line_no,
            contract_id: obj.contract_id,
            maint_org: obj.maint_org,
            work_type_id: obj.work_type_id,
            product: obj.description,
            shortDesc: text.kommun + " - " + obj.description
        });
        setSla({
            ...sla,
            min_end_time: obj.min_end_time,
            min_start_time: obj.min_start_time
        });
    }

    const styles = {
        control: (base) => ({
            ...base,
            backgroundColor: "#D8D9DB",
            borderColor: "red",
            border: validateIsErr[jsonText] === true ? "" : "none",
            borderRadius: "10px",
        }),
        singleValue: (base) => ({
            ...base,
            padding: "5px 10px",
            borderRadius: 5,
            display: "flex",
        }),
        menuList: (base) => ({
            ...base,
            maxHeight: "230px"
        })
    };
    return (
        <>
            <h6 className="txtH6dd">{headerText}
                {" "}
                <FaInfoCircle id={`info-circel-${jsonText}`} className="infoCircelTop" data-tooltip-html={tooltipText} display={tooltipText.length === 0 ? ("none") : ("")} />
                <Tooltip anchorId={`info-circel-${jsonText}`} className="infoCircelTop2" multiline={true} data-html={true} html />
            </h6>
            <Select onChange={handleChange} className="reactSelect" name={jsonText} isSearchable={true}
                value={{ value: remValLine, label: text.product === "" ? ("Välj produkt...") : (text.product) }}
                options={ddData.map((x) => ({ value: JSON.stringify(x), label: x.description }))}
                styles={styles}>
            </Select >
            <br />
            <small className="errSmalldd">{validateIsErr[jsonText] === true ? errText : ""}</small>
        </>
    )
}

export default DropDownSearchD2