import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../authConfig";
import ButtonS1 from "./ButtonS1";

/**
 * Renders a drop down button with child buttons for logging in with a popup or redirect
 */
export const SignInButton = () => {
  const { instance } = useMsal();

  const handleLogin = (loginType) => {
    console.log("Login");
    if (loginType === "popup") {
      instance.loginPopup(loginRequest).catch((e) => {
        console.log(e);
      });
    } else if (loginType === "redirect") {
      instance.loginRedirect(loginRequest).catch((e) => {
        console.log(e);
      });
    }
  };
  return (
    <>
      {/*<ButtonS1 text="Logga in (popup)" clickButton={() => handleLogin("popup")} />*/}
      <ButtonS1 text="Logga in (redirect)" clickButton={() => handleLogin("redirect")} />
    </>
  );
};
