import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'react-tooltip/dist/react-tooltip.css';
import { PublicClientApplication, EventType, InteractionType } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig, b2cPolicies } from './authConfig';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from "history";
const browserHistory = createBrowserHistory({ basename: '' });

const root = ReactDOM.createRoot(document.getElementById('root'));
const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.addEventCallback((event) => 
{
  if (event.eventType === EventType.LOGIN_FAILURE) 
  {
    if (event.error && event.error.errorMessage.indexOf("AADB2C90118") > -1) 
    {
        if (event.interactionType === InteractionType.Redirect) 
        {
          const resetPasswordRequest = {
            authority: b2cPolicies.authorities.forgotPassword.authority,
            scopes: [],
          };
          msalInstance.loginRedirect(resetPasswordRequest);
        }
    }
  }
});

var reactPlugin = new ReactPlugin();
var appInsights = new ApplicationInsights({
    config: {
        connectionString: process.env.REACT_APP_APPLICATION_INSIGHTS_CONNECTIONSTRING,
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: { history: browserHistory }
        }
    }
});
appInsights.loadAppInsights();

 var telemetryInitializer = (envelope) => {  
  const realWidth = window.screen.width * window.devicePixelRatio;
  const realHeight = window.screen.height * window.devicePixelRatio; 
  envelope.data = envelope.data || {}; envelope.data.screenResolution = `${realWidth}x${realHeight}`; 
};
appInsights.addTelemetryInitializer(telemetryInitializer);

root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <App />
    </MsalProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
