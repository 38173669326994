
const SavedWorkOrder = ({ savedWo, setChosenWo, closeSavedWorkOrders, setIdToDelete }) => {
    const choseWorkOrder = async () => {
        let tmpChosenWo = JSON.parse(savedWo.JsonData);
        tmpChosenWo = { ...tmpChosenWo, savedId: savedWo.Id };
        setChosenWo(tmpChosenWo);
        closeSavedWorkOrders(1);
    }
    const choseWorkOrderToDelete = async () => {
        setIdToDelete(savedWo.Id)
    }

    const colorMappings = (date) => {
        let dateAdded = new Date(date);
        let difference = new Date().getTime() - dateAdded.getTime();
        let totalDays = Math.ceil(difference / (1000 * 3600 * 24));

        if (totalDays > 150) { // 150 = 5 x 30 days = 5 months
            return "color7";
        }
        else if (totalDays > 120) { // 120 = 4 x 30 days = 4 months
            return "color1";
        }

        return null;
    }

    return (
        <div class="div-table-row">
            <div class="div-table-col-small div-table-col-name">
                <div className={"divText"}>{colorMappings(savedWo.DateAdd) !== null ? (<><div className={`statusCircle ${colorMappings(savedWo.DateAdd)}b`} />{savedWo.SaveName}</>) : (savedWo.SaveName)}</div>
            </div>
            <div class="div-table-col-small div-table-col-date">{savedWo.DateAdd.replace("T", " ").substring(0, 16)}</div>
            <div class="div-table-col-small">
                <button className="btn" onClick={choseWorkOrder}>
                    Välj
                </button>
            </div>
            <div class="div-table-col-small">
                <button className="btn" onClick={choseWorkOrderToDelete}>
                    Ta bort
                </button>
            </div>
        </div>
    )
}

export default SavedWorkOrder