import "../../css/pc/mainWindow.css";
import WorkOrderItems from "./lists/WorkOrderItems";
import EltelLogo from "../../img/eltel.png";
import Front1 from "../../img/front1.jpg";
import Front2 from "../../img/front2.jpg";
import TextBoxSearch from "./controls/TextBoxSearch";
import { FaSearch } from "react-icons/fa";
import ButtonS1 from "./controls/ButtonS1";
import { useState, useEffect } from "react";
import NewWoMainWindow from "./NewWO/NewWoMainWindow";
import ExistingWOMain from "./ExistingWO/ExistingWOMain";
import DropDownSearchLeft from "./controls/DropDownSearchLeft";
import { useIsAuthenticated } from '@azure/msal-react';
import { SignInButton } from "./controls/SignInButton"
import { SignOutButton } from './controls/SignOutButton';
import { useMsal, MsalAuthenticationTemplate } from '@azure/msal-react';
import { EventType, InteractionType } from '@azure/msal-browser';
import { b2cPolicies, loginRequest, protectedResources } from '../../authConfig';
import MainAdmin from "./admin/MainAdmin";
import { compareIssuingPolicy } from './utils/claimUtils';
import { Container, Row, Col, Card, Navbar, Nav, Button, Ratio } from 'react-bootstrap';
import { FaUserShield, FaPlus, FaRegUser, FaBars, FaRegFolderOpen } from 'react-icons/fa';
import { VscClose } from "react-icons/vsc";

const MainWindowContent = () => {
  //const customer_no = "2419";
  //const agreement = "'13123'";
  const ApiDomain = process.env.REACT_APP_BACKEND_API_HOST;
  const [showCreateWO, setShowCreateWO] = useState(false);
  const [showWIinfo, setShowWOinfo] = useState(false);
  const [showAdmin, setShowWAdmin] = useState(false);
  const [item, setItem] = useState("");
  const [status, setStatus] = useState("");
  const [searchText, setSearchText] = useState("");
  const [userSetting, setUserSetting] = useState({ CustomerNo: "", Agreements: "" });
  const [workOrderListData, setWorkOrderListData] = useState([]);
  const [workOrderListError, setWorkOrderListError] = useState(false);
  const [showWoList, setShowWoList] = useState(false);
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const [showSavedWorkOrders, setShowSavedWorkOrders] = useState(0);

  useEffect(() => {
    const callbackId = instance.addEventCallback((event) => {
      if (
        (event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
        event.payload.account
      ) {
        //     /**
        //      * For the purpose of setting an active account for UI update, we want to consider only the auth
        //      * response resulting from SUSI flow. "tfp" claim in the id token tells us the policy (NOTE: legacy
        //      * policies may use "acr" instead of "tfp"). To learn more about B2C tokens, visit:
        //      * https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
        //      */
        //     if (compareIssuingPolicy(event.payload.idTokenClaims, b2cPolicies.names.editProfile)) {
        //         // retrieve the account from initial sing-in to the app
        //         const originalSignInAccount = instance
        //             .getAllAccounts()
        //             .find(
        //                 (account) =>
        //                     account.idTokenClaims.oid === event.payload.idTokenClaims.oid &&
        //                     account.idTokenClaims.sub === event.payload.idTokenClaims.sub && 
        //                     compareIssuingPolicy(account.idTokenClaims, b2cPolicies.names.signUpSignIn)        
        //             );

        //         let signUpSignInFlowRequest = {
        //             authority: b2cPolicies.authorities.signUpSignIn.authority,
        //             account: originalSignInAccount,
        //         };

        //         // silently login again with the signUpSignIn policy
        //         instance.ssoSilent(signUpSignInFlowRequest);
        //     }

        /**
         * Below we are checking if the user is returning from the reset password flow.
         * If so, we will ask the user to reauthenticate with their new password.
         * If you do not want this behavior and prefer your users to stay signed in instead,
         * you can replace the code below with the same pattern used for handling the return from
         * profile edit flow
         */
        if (compareIssuingPolicy(event.payload.idTokenClaims, b2cPolicies.names.forgotPassword)) {
          let signUpSignInFlowRequest = {
            authority: b2cPolicies.authorities.signUpSignIn.authority,
            scopes: [
              ...protectedResources.apiTodoList.scopes.read,
              ...protectedResources.apiTodoList.scopes.write,
            ],
          };
          instance.loginRedirect(signUpSignInFlowRequest);
        }
      }

      if (event.eventType === EventType.LOGIN_FAILURE) {
        // Check for forgot password error
        if (event.error && event.error.errorMessage.includes('AADB2C90118')) {
          const resetPasswordRequest = {
            authority: b2cPolicies.authorities.forgotPassword.authority,
            scopes: [],
          };
          instance.loginRedirect(resetPasswordRequest);
        }
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
    // eslint-disable-next-line
  }, [instance]);

  const clickButton = (e) => {
    //setAvtaltxt("⬇️ Välj ett avtal ⬇️");
    //alert("TEST " + e.target.value)
    setShowSavedWorkOrders(0);
    setShowCreateWO(true);
  };

  const clickShowSavedWorkOrders = (e) => {
    setShowSavedWorkOrders(1);
    setShowCreateWO(true);
  };

  const clickToggleWorkOrderList = (e) => {
    setShowWoList(!showWoList);
  };

  const clickButtonAdmin = (e) => {
    setShowWAdmin(true);
  };

  function updateWoList() {
    if (userSetting.CustomerNo.length !== 0) {
      getInstance("WOLIST");
    }
  }

  async function ShowWoinfo(WOitem) {
    //alert("wono " + WOitem.wono);
    setItem(WOitem);
    setShowWOinfo(true);
  }
  useEffect(() => {
    console.log({ accounts });
  }, [accounts])

  /*useEffect(() => {
    console.log("showSavedWorkOrders --->");
    console.log(showSavedWorkOrders);
  }, [showSavedWorkOrders])*/

  function getInstance(type) {
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        //callMsGraph(response.accessToken).then((response) => setGraphData(response));
        if (type === "WOLIST") {
          myFetch(response);
        } else if (type === "USERSETTING") {
          myFetchUserSettings(response);
        }
      })
      .catch((error) => {
        instance
          .acquireTokenPopup({
            ...loginRequest,
            account: accounts[0],
          })
          .then((response) => {
            //callMsGraph(response.accessToken).then((response) => setGraphData(response));
            if (type === "WOLIST") {
              myFetch(response);
            } else if (type === "USERSETTING") {
              myFetchUserSettings(response);
            }
            console.log(error);
          });
      });
  }

  useEffect(() => {
    const getWorkOrderList = async () => {
      if (userSetting.CustomerNo.length !== 0) {
        getInstance("WOLIST");
      }
    }
    getWorkOrderList();
  }, [status]);

  // useEffect(() => {
  //   const getWorkOrderList = async () => {
  //     if (userSetting.CustomerNo.length !== 0) {
  //       getInstance("WOLIST");
  //     }
  //   }
  //   getWorkOrderList();
  // }, [userSetting]);

  useEffect(() => {
    if (isAuthenticated) {
      getInstance("USERSETTING");
    }
  }, [isAuthenticated])

  function myFetch(response) {
    const headers = new Headers();
    const bearer = `Bearer ${response.accessToken}`;

    //console.log("bearer -->");
    //console.log(bearer);

    headers.append("Authorization", bearer);

    const options = {
      method: "GET",
      headers: headers
    };
    console.log("options -->");
    console.log(options);
    setWorkOrderListData([]);
    let url = "";
    if (status !== "na") {
      url = `https://${ApiDomain}/Ora/ora/get/wo/list?customer_no=${userSetting.CustomerNo}&agreement=${userSetting.Agreements}&status=${status}&searchText=${searchText}`;
    } else if (searchText !== "") {
      url = `https://${ApiDomain}/Ora/ora/get/wo/list?customer_no=${userSetting.CustomerNo}&agreement=${userSetting.Agreements}&searchText=${searchText}`;
    } else {
      url = `https://${ApiDomain}/Ora/ora/get/wo/list?customer_no=${userSetting.CustomerNo}&agreement=${userSetting.Agreements}&searchText=${searchText}`;
    }
    console.log(`URL: ${url}`);
    console.log(`ApiDomain: ${ApiDomain}`);

    const res = fetch(url, options).then(function (response) {
      if (!response.ok) {
        setWorkOrderListError(true);
      }

      return response.json();
    }).then((data) => {
      console.log(data);
      var s = data.sort((a, b) => { return new Date(b.REG_DATE) - new Date(a.REG_DATE) })
      setWorkOrderListData(s);
    }
    ).catch(error => console.log(error));
    console.log("res -->");
    console.log(res);
  }

  function myFetchUserSettings(response) {
    console.log("*** myFetchUserSettings ***");
    const headers = new Headers();
    const bearer = `Bearer ${response.accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
      method: "GET",
      headers: headers
    };

    let url = "";
    url = `https://${ApiDomain}/usersettings/sql/get/user/settings`;
    console.log(`myFetchUserSettings URL: ${url}`);
    console.log(`ApiDomain: ${ApiDomain}`);
    const res = fetch(url, options).then((response) => response.json()).then((data) => {
      console.log(data);
      setUserSetting(data);
    }
    ).catch(error => console.log(error));
    console.log("res -->");
    console.log(res);
  }

  const getWorkOrderListSearch = async () => {
    getInstance("WOLIST");
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      getInstance("WOLIST");
    }
  }

  function disableCreateWorkOrderButton() {
    if (userSetting.CustomerNo.length == 0 || userSetting.Agreements.length == 0) {
      return true;
    }
    return false;
  }

  const refresh = () => window.location.reload(true);

  //<img src={BackFront} alt="eltelfront" className="backFront" />
  return (
    <Container fluid>
      <Row style={{ minHeight: '100vh' }}>
        <Col xs lg={4} xl={3} className="leftDiv">
          <Container fluid>
            <Row>
              <Navbar>
                <Container>
                  <Navbar.Brand>
                    <img src={EltelLogo} alt="eltellogo" className="logo" onClick={refresh} />
                    <span className="separator_line"></span>
                    <span style={{ color: '#ffffff', fontSize: '1rem' }}>Self Service Portal</span>
                  </Navbar.Brand>
                  <Nav className="d-lg-none d-flex">
                    {isAuthenticated ? (
                      <>
                        <Button variant="primary" onClick={clickButton} className="create-workorder-button" style={{ padding: '8px 10px' }} disabled={disableCreateWorkOrderButton()}>
                          <FaPlus style={{ margin: '0', fontSize: '1.2em', verticalAlign: 'sub' }} />
                        </Button>
                        <Button variant="primary" onClick={clickShowSavedWorkOrders} className="create-workorder-button" style={{ padding: '8px 10px' }} disabled={disableCreateWorkOrderButton()}>
                          <FaRegFolderOpen style={{ margin: '0', fontSize: '1.2em', verticalAlign: 'sub' }} />
                        </Button>
                      </>
                    ) : (<></>)}
                    <Button onClick={clickToggleWorkOrderList} className="show-workorders-button">
                      {showWoList ? (
                        <VscClose style={{ margin: '0', fontSize: '2.5em', verticalAlign: 'sub' }} />
                      ) :
                        (
                          <FaBars style={{ margin: '0', fontSize: '2em', verticalAlign: 'sub' }} />
                        )}
                    </Button>
                  </Nav>
                </Container>
              </Navbar>
              <Container style={{ backgroundColor: '#ffffff' }}>
                <Navbar className={showWoList ? 'd-lg-none justify-content-center py-0' : 'd-none'}>
                  <Nav className="align-items-center">
                    <Nav.Item>
                      <div className="me-2 text-uppercase"><FaRegUser className="icon me-1 mb-1" />{isAuthenticated ? accounts[0].name : " "}</div>
                    </Nav.Item>
                    <Nav.Item>
                      <div className="vr menu-divider mx-4 d-block"></div>
                    </Nav.Item>
                    <Nav.Item className="mobile-sign-out-button">
                      {isAuthenticated ? <SignOutButton /> : <SignInButton />}
                    </Nav.Item>
                  </Nav>

                </Navbar>
              </Container>
            </Row>
            <Row className={showWoList ? '' : 'd-none d-lg-block'}> {/* Hide on screens smaller than lg or display if showWoList is true */}
              <Col>
                <TextBoxSearch searchText={searchText} setSearchText={setSearchText} handleKeyDown={handleKeyDown} />
                <FaSearch className="fasearch" onClick={getWorkOrderListSearch} />
                <DropDownSearchLeft status={status} setStatus={setStatus} workOrderFilter={userSetting.WorkOrderFilter} updateWoList={updateWoList} />
                {workOrderListError ? (
                  <div className="errorMessage">Det går inte att hämta arbetsordrar just nu, ladda om sidan för att försöka igen eller kontakta Eltel SPOC om problemet kvarstår.</div>
                ) : workOrderListData.length > 0 ? (
                  <WorkOrderItems ShowWoinfo={ShowWoinfo} customer_no={userSetting.CustomerNo} workOrderListData={workOrderListData} setWorkOrderListData={setWorkOrderListData} />
                ) : (<></>)}
              </Col>
            </Row>
          </Container>
        </Col>
        <Col lg={8} xl={9} className={showWoList ? 'd-none' : 'mainContent align-items-center'}>
          <Navbar bg="transparent" className="d-none d-lg-block">
            <Container>
              <Nav>
                <Nav.Item>
                  {isAuthenticated ? (
                    <>
                      <Button variant="primary" onClick={clickButton} className="create-workorder-button" disabled={disableCreateWorkOrderButton()}>
                        <FaPlus className="icon" />
                        Skapa arbetsorder
                      </Button>
                      <Button variant="primary" onClick={clickShowSavedWorkOrders} className="create-workorder-button" disabled={disableCreateWorkOrderButton()}>
                        <FaRegFolderOpen className="icon" />
                        Sparade arbetsordrar
                      </Button>
                    </>
                  ) : (<></>)}
                </Nav.Item>
              </Nav>
              <Nav className="justify-content-end align-items-center">
                <Nav.Item>
                  <div className="me-2 text-uppercase"><FaRegUser className="icon me-1 mb-1" />{isAuthenticated ? accounts[0].name : " "}</div>
                </Nav.Item>
                <Nav.Item>
                  <div className="vr menu-divider me-1 mt-1"></div>
                </Nav.Item>
                <Nav.Item>
                  {isAuthenticated && userSetting.IsAdmin === true ?
                    (<Button variant="primary" onClick={clickButtonAdmin} className="admin-button">
                      <FaUserShield className="icon" />
                      ADMIN
                    </Button>)
                    : (<></>)}
                </Nav.Item>
                <Nav.Item>
                  {isAuthenticated ? <SignOutButton /> : <SignInButton />}
                </Nav.Item>
              </Nav>
            </Container>
          </Navbar>


          <Container className="h-75 justify-content-start" fluid>
            <Row className="align-items-center h-100">
              <Col lg={4}>


                <div className="d-lg-none w-100 position-relative welcome-images-mobile">
                  <img src={Front2} alt="eltelfront" className="front2-mobile" />
                  <img src={Front1} alt="eltelfront" className="front1-mobile" />
                </div>


                <Card bg="transparent" className="border-0 align-items-center" >
                  <Card.Body>
                    <Card.Title>Välkommen till Eltel<br /> {isAuthenticated ? accounts[0].name : " "}</Card.Title>
                    <Card.Text>
                      <p>Har du frågor angående din <b>order</b>, vänligen kontakta Eltel kundservice</p>
                        Tel: 031-7454500<br />
                        Mail: <a href="mailto:SPOC.SWE@eltelnetworks.se">SPOC.SWE@eltelnetworks.se</a><br /><br />
                      <p>
                        Har du frågor om vår <b>kundportal</b><br />
                        Kontakta oss här:<br /><a href="mailto:essp-customersupport@eltelnetworks.com">essp-customersupport@eltelnetworks.com</a>
                      </p>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={3}>

                <div className="welcome-images d-none d-lg-block">
                  <img src={Front2} alt="eltelfront" className="front2" />
                  <img src={Front1} alt="eltelfront" className="front1" />
                </div>

              </Col>
            </Row>
          </Container>


          {showCreateWO ? (
            <NewWoMainWindow setShowCreateWO={setShowCreateWO} customer_no={userSetting.CustomerNo}
              agreement={userSetting.Agreements} accounts={accounts[0]} updateWoList={updateWoList} showSavedWorkOrders={showSavedWorkOrders} setShowSavedWorkOrders={setShowSavedWorkOrders} />
          ) : (
            <></>
          )}
          {showWIinfo ? (
            <ExistingWOMain item={item} setShowWOinfo={setShowWOinfo} showWIinfo={showWIinfo} user={accounts[0].name} updateWoList={updateWoList} />
          ) : (
            <></>
          )}
          {showAdmin ? (
            <MainAdmin setShowWAdmin={setShowWAdmin} />
          ) : (<></>)
          }
        </Col>
      </Row>
    </Container>
  );
};

export const MainWindow = () => {
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={loginRequest}
    >
      <MainWindowContent />
    </MsalAuthenticationTemplate>
  );
};
