import TextBoxT1 from "../controls/TextBoxT1";

const Step2 = ({ setText, text, validateIsErr }) => {
    return (
        <>
            {" "}
            <div class="div-table">
                <div class="div-table-row">
                    <div class="div-table-col-header">
                        <h3 className="stepH3">Slutkund/Företag</h3>
                    </div>
                </div>
                <div class="div-table-row">
                    <div class="div-table-col">
                        <TextBoxT1 headerText="Företagsnamn" jsonText="customerCompanyName" setText={setText} text={text}
                            toolTip="Här fyller du i information om ev slutkund. Vid stationsjobb eller transportnät skrivs detta in."
                            errText="Måste ha ett värde." validateIsErr={validateIsErr} maxLength="30" />
                    </div>
                    <div class="div-table-col">
                        {" "}
                    </div>
                </div>
                <div class="div-table-row">
                    <div class="div-table-col">
                        <TextBoxT1 headerText="Kontaktperson namn" jsonText="customerName" setText={setText} text={text}
                            errText="Måste ha ett värde." validateIsErr={validateIsErr} maxLength="50" />
                    </div>
                    <div class="div-table-col">
                        <TextBoxT1 headerText="Telefon" jsonText="customerPhone" setText={setText} text={text}
                            errText="Obligatorisk,+ och 0-9 är ok." validateIsErr={validateIsErr} maxLength="12" />
                    </div>
                </div>
                <div class="div-table-row">
                    <div class="div-table-col">
                        <TextBoxT1 headerText="Adress" jsonText="customerAddress" setText={setText} text={text}
                            errText="Måste ha ett värde." validateIsErr={validateIsErr} maxLength="30" />
                    </div>
                    <div class="div-table-col">
                        <TextBoxT1 headerText="Postnummer" jsonText="customerPostalcode" placeholder="12345" setText={setText} text={text}
                            errText="Obligatorisk, 5 siffror." validateIsErr={validateIsErr} minLength="5" maxLength="6" />
                    </div>
                </div>
                <div class="div-table-row">
                    <div class="div-table-col">
                        <TextBoxT1 headerText="Ort" jsonText="customerCity" setText={setText} text={text}
                            errText="Måste ha ett värde." validateIsErr={validateIsErr} maxLength="30" />
                    </div>
                    <div class="div-table-col">
                        {" "}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Step2;
