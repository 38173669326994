import React from 'react'

const TabWorkDone = ({ woDetails }) => {
    return (
        <>
            <div className="divWorkDescLo"
                dangerouslySetInnerHTML={{
                    __html:
                        woDetails[0].PERF_ACTION !== null ?
                            (woDetails[0].PERF_ACTION.replace(/£\$/g, "<b>").replace(/\$£/g, "</b>").replace(/\;\;/g, "<br />")) :
                            ("Utförtarbetestext saknas.")
                }} />
        </>
    )
}

export default TabWorkDone