import TextBoxT1 from "../controls/TextBoxT1";
import Checkbox from "../controls/CheckBox";

const Step3 = ({ setText, text, validateIsErr }) => {
    return (
        <>
            {" "}
            <div class="div-table">
                <div class="div-table-row">
                    <div class="div-table-col-header">
                        <h3 className="stepH3">Slutkund/Företag</h3>
                    </div>
                </div>
                <div class="div-table-row">
                    <div class="div-table-col">
                        <TextBoxT1 headerText="Nyckel ID" jsonText="customerKeyID" setText={setText} text={text}
                            errText="" validateIsErr={validateIsErr} maxLength="30" />
                    </div>
                    <div class="div-table-col">
                        <TextBoxT1 headerText="Adress för upphämtning" jsonText="customerKeyAddress" setText={setText} text={text}
                            errText="" validateIsErr={validateIsErr} maxLength="30" />
                    </div>
                </div>
                <div class="div-table-row">
                    <div class="div-table-col">
                        <TextBoxT1 headerText="Namn" jsonText="customerKeyName" setText={setText} text={text}
                            errText="" validateIsErr={validateIsErr} maxLength="50" />
                    </div>
                    <div class="div-table-col">
                        <TextBoxT1 headerText="Telefon" jsonText="customerKeyPhone" setText={setText} text={text}
                            errText="Ska innehålla siffror." validateIsErr={validateIsErr} maxLength="12" />
                    </div>
                </div>
                <div class="div-table-row">
                    <div class="div-table-col">
                        <Checkbox headerText="Ring för åtkomst/nyckel" jsonText="customerCallForAccess" setText={setText} text={text}
                            toolTip="Ange om telefonnumret behöver ringas för åtkomst eller tillgång till nyckel." checkedValue="Ja" />
                    </div>
                    <div class="div-table-col">{" "}</div>
                </div>
            </div>
        </>
    );
}

export default Step3