import React from 'react'
import { useState, useEffect } from "react";
import { FaTimes, FaPlus } from "react-icons/fa";
import { fixDateFormat } from "../../../components/pc/Utils.js"

const User = ({ user, sameAsBefore, saveData, deleteData }) => {
    const [addUser, setAddUser] = useState(false);
    const [userChange, setUserChange] = useState(
        {
            UserId: -1,
            UserName: -1,
            CustomerNo: "",
            Agreements: ""
        });

    const addNewUserSetting = (e) => {
        console.log("Add");
        setAddUser(!addUser);
    };

    const deleteUserSetting = (e) => {
        if (window.confirm("Är du säker på att du vill ta bort denna rad?")) {
            console.log("Delete OK");
            deleteData(user);
        } else {
            console.log("Delete Cancel");
        }
    };

    function handleChangeCustomer(evt) {
        setUserChange({
            ...userChange,
            CustomerNo: evt.target.value,
            UserId: user.UserId,
            UserName: user.UserName,
        })
    }

    function handleChangeAgreement(evt) {
        setUserChange({
            ...userChange,
            Agreements: evt.target.value
        })
    }

    const btnSave = (e) => {
        saveData(userChange);
        setAddUser(false);
    };

    return (
        <div className='divUser'>
            {
                sameAsBefore ? (<></>) : (<>
                    <div className='divLine' />
                    <span className='pUserName'>{user.FullName}{" "}</span><span>-&nbsp;{user.UserName}{" "}<FaPlus className='imgAdd' title='Lägg till' onClick={addNewUserSetting} /></span>
                    <p className='pLastLoggedIn'>Senast inloggad:&nbsp;{user.LastLogonTimeStamp === null ? "-" : fixDateFormat(user.LastLogonTimeStamp)}</p>
                </>
                )
            }
            {
                user.CustomerNo === "" && user.Agreements === "" ? (<></>) :
                    (
                        <div className='divUserName'>
                            Kund Id: {" "}
                            {user.CustomerNo} {" "}
                            Avtal: {" "}
                            {user.Agreements}
                            <FaTimes className='imgTrash' title='Ta bort' onClick={deleteUserSetting} />
                        </div>
                    )
            }
            {
                addUser ? (
                    <div className='divUserName'>
                        Kund Id
                        <input type="number"
                            className='txtUserSetting'
                            onChange={handleChangeCustomer}
                            value={userChange.CustomerNo} />
                        Avtal
                        <input type="number"
                            className='txtUserSetting'
                            onChange={handleChangeAgreement}
                            value={userChange.Agreements} />
                        <button className="btn" onClick={btnSave} disabled={userChange.CustomerNo === "" || userChange.Agreements === "" ? (true) : (false)}>
                            Spara
                        </button>
                    </div>
                ) : (<></>)
            }
        </div>
    )
}

export default User