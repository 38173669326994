import "./CheckBox.css";
import { FaInfoCircle } from "react-icons/fa";
import { Tooltip } from 'react-tooltip'
import { useState } from 'react';

const Checkbox = ({ setText, text, headerText, jsonText, toolTip = "", checkedValue }) => {
    const [checked, setChecked] = useState(text[jsonText] === "Ja" ? true : false);

    function handleChange(evt) {
        setChecked(!checked);
        let newValue = evt.target.checked ? checkedValue : "";
        setText({ ...text, [evt.target.name]: newValue });
    }

    return (
        <div className="checkbox-wrapper">
            <label>
                <input type="checkbox" id="yes" name={jsonText} value={checkedValue} checked={checked} onChange={handleChange} />
                <span className="checkboxLabel">{headerText}</span>
            </label>
            {" "}
            <FaInfoCircle id={`info-circel-${jsonText}`} className="checkbox-info-circle" data-tooltip-html={toolTip} display={toolTip.length === 0 ? ("none") : ("")} />
            <Tooltip anchorId={`info-circel-${jsonText}`} multiline={true} data-html={true} />
        </div>
    )
}

export default Checkbox