import React from 'react'
import { FaEdit } from "react-icons/fa";
import { Tooltip } from 'react-tooltip';
import { useState, useEffect } from "react";
import waitonCreate from "../../../assets/load.gif";

const TabWorkDesc = ({ woDetails, woUpdateData, setWoUpdateData, saveUpdate, user = "", setUpdateMsg, updateMsg, setIsUpdating, isUpdating }) => {
    const [isEdit, setIsEdit] = useState(false);
    const [longWorkDesctmp, setLongWorkDesctmp] = useState("");

    const edit = (e) => {
        setUpdateMsg({ ...updateMsg, isOk: false, message: "" });
        setIsEdit(!isEdit);
    };

    useEffect(() => {
        console.log(woUpdateData);
        if (woUpdateData.test1 === "UPDATE") {
            saveUpdate();
            setWoUpdateData({
                ...woUpdateData, test1: ""
            })
        }
    }, [woUpdateData]);

    const saveWo = (e) => {
        console.log("Save");
        setIsUpdating(true);
        setUpdateMsg({ ...updateMsg, isOk: false, message: "" });
        let date = new Date().toLocaleString() + "";
        let tmp = "Uppdatering av " + user + " - " + date + "\n" + longWorkDesctmp + "\n\n" + woDetails[0].WORK_DESCR_LO;
        setWoUpdateData({
            ...woUpdateData,
            longWorkDesc: tmp,
            test1: "UPDATE"
        })
        setLongWorkDesctmp("");
    };

    function handleChange(evt) {
        //setWoUpdateData({ ...woUpdateData, longWorkDesc: evt.target.value })
        setLongWorkDesctmp(evt.target.value);
    }

    if (isEdit) {
        return (
            <>
                <FaEdit id="faediticon" data-tooltip-content="Ändra data" onClick={edit} className="faEdit" />
                <Tooltip anchorId="faediticon" multiline={true} data-html={true} /><br />
                <label htmlFor="txtAreaAdd">Lägg till text</label><br />
                <textarea id="txtAreaAdd" value={longWorkDesctmp} onChange={handleChange} name="longWorkDesc" className="txtAreaExisting" rows="5" cols="100" maxLength={(20000 - woDetails[0].WORK_DESCR_LO.length) < 0 ? 0 : (20000 - woDetails[0].WORK_DESCR_LO.length)} ></textarea><br />
                <p className={"textAreaCounter" + ((longWorkDesctmp.length + woDetails[0].WORK_DESCR_LO.length) >= 20000 ? " textAreaCounterMax" : "")}>
                    {
                        (longWorkDesctmp.length + woDetails[0].WORK_DESCR_LO.length) > 15000 ?
                            (
                                (longWorkDesctmp.length + woDetails[0].WORK_DESCR_LO.length) < 20000 ?
                                    (
                                        (longWorkDesctmp.length + woDetails[0].WORK_DESCR_LO.length).toLocaleString('sv-SE') + " av 20 000"
                                    ) : "20 000 av 20 000"
                            ) : ""
                    }
                </p>
                <button className="btn" onClick={saveWo} disabled={longWorkDesctmp.length === 0 || isUpdating ? true : false}>
                    Spara
                </button>{" "}<div>{updateMsg.message}</div>
                {
                    isUpdating ? <img src={waitonCreate} alt="loading..." className="loadGifsmall" /> : <></>
                }
                <div className="divWorkDescLoEdit">
                    {woDetails[0].WORK_DESCR_LO}
                </div>
            </>
        )
    } else {
        return (
            <>
                <FaEdit id="faediticon" data-tooltip-content="Ändra data" onClick={edit} className="faEdit" />
                <Tooltip anchorId="faediticon" multiline={true} data-html={true} />
                <div className="divWorkDescLo">
                    {woDetails[0].WORK_DESCR_LO}
                </div>
            </>
        )
    }
}

export default TabWorkDesc