import "./App.css";
import "./css/pc/controls/loginForm.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { MainWindow } from "./components/pc/MainWindow";

function App() {
  return <MainWindow />;
}

export default App;
