import TextBoxT1 from "../controls/TextBoxT1";
import Checkbox from "../controls/CheckBox";

const Step5 = ({ setText, text, validateIsErr }) => {
    return (
        <>
            {" "}
            <div class="div-table">
                <div class="div-table-row">
                    <div class="div-table-col-header">
                        <h3 className="stepH3">Tillträde Site</h3>
                    </div>
                </div>
                <div class="div-table-row">
                    <div class="div-table-col">
                        <TextBoxT1 headerText="Nyckel ID" jsonText="accessSiteKeyID" setText={setText} text={text}
                            errText="" validateIsErr={validateIsErr} maxLength="30" />
                    </div>
                    <div class="div-table-col">
                        <TextBoxT1 headerText="Adress" jsonText="accessSiteAddress" setText={setText} text={text}
                            errText="" validateIsErr={validateIsErr} maxLength="30" />
                    </div>
                </div>
                <div class="div-table-row">
                    <div class="div-table-col">
                        <TextBoxT1 headerText="Postnummer" jsonText="accessSitePostalcode" setText={setText} text={text}
                            errText="Fem siffror" validateIsErr={validateIsErr} maxLength="6" />
                    </div>
                    <div class="div-table-col">
                        <TextBoxT1 headerText="Ort" jsonText="accessSiteCity" setText={setText} text={text}
                            errText="" validateIsErr={validateIsErr} maxLength="30" />
                    </div>
                </div>
                <div class="div-table-row">
                    <div class="div-table-col">
                        <TextBoxT1 headerText="Namn" jsonText="accessSiteName" setText={setText} text={text}
                            errText="" validateIsErr={validateIsErr} maxLength="30" />
                    </div>
                    <div class="div-table-col">
                        <TextBoxT1 headerText="Telefon" jsonText="accessSitePhone" setText={setText} text={text}
                            errText="+ och 0-9 är tillåtna" validateIsErr={validateIsErr} maxLength="12" />
                    </div>
                </div>
                <div class="div-table-row">
                    <div class="div-table-col">
                        <Checkbox headerText="Ring för åtkomst/nyckel" jsonText="accessSiteCallForAccess" setText={setText} text={text}
                            toolTip="Ange om telefonnumret behöver ringas för åtkomst eller tillgång till nyckel." checkedValue="Ja" />
                    </div>
                    <div class="div-table-col"></div>
                </div>
            </div>
        </>
    );
}

export default Step5