import "./dropDown.css";

const CustomerDropDownSelector = ({ headerText, ddData, customerId, setCustomerId }) => {

    function handleChange(evt) {
        setCustomerId(evt.target.value);
    }

    const uniqueCustomers = [...new Set(ddData.map((item) => item.CustomerNo))];

    return (
        <>
            <h6 className="txtH6dd" style={{marginLeft: '0'}}>{headerText}</h6>
            <select onChange={handleChange} value={customerId} >
                <option value="NA"> -- Alla -- </option>
                {uniqueCustomers.map((x) => (
                    <option key={x} value={x}>{x}</option>
                ))}
            </select><br />
        </>
    )
}

export default CustomerDropDownSelector