import TextBoxT1 from "../controls/TextBoxT1";

const Step7 = ({ setText, text, validateIsErr }) => {
    return (
        <>
            {" "}
            <div class="div-table">
                <div class="div-table-row">
                    <div class="div-table-col-header">
                        <h3 className="stepH3">Material detaljer</h3>
                    </div>
                </div>
                <div class="div-table-row">
                    <div class="div-table-col">
                        <TextBoxT1 headerText="Krav på utrustning/instrument" jsonText="mtrlEquipment" setText={setText} text={text}
                            errText="" validateIsErr={validateIsErr} maxLength="50" />
                    </div>
                    <div class="div-table-col">
                        <TextBoxT1 headerText="Reservdel 1" jsonText="sparePart1" setText={setText} text={text}
                            errText="" validateIsErr={validateIsErr} maxLength="50" />
                    </div>
                </div>
                <div class="div-table-row">
                    <div class="div-table-col">
                        <TextBoxT1 headerText="Reservdel 2" jsonText="sparePart2" setText={setText} text={text}
                            errText="" validateIsErr={validateIsErr} maxLength="50" />
                    </div>
                    <div class="div-table-col">
                        <TextBoxT1 headerText="Reservdel 3" jsonText="sparePart3" setText={setText} text={text}
                            errText="" validateIsErr={validateIsErr} maxLength="50" />
                    </div>
                </div>
                <div class="div-table-row">
                    <div class="div-table-col">
                        <TextBoxT1 headerText="Returhantering mtrl" jsonText="mtrlReturnMtrl" setText={setText} text={text}
                            errText="" validateIsErr={validateIsErr} maxLength="50"
                            toolTip="Finns speciell hantering utav material skrivs denna in här." />
                    </div>
                    <div class="div-table-col">
                        <TextBoxT1 headerText="Info returhantering" jsonText="infoMtrlReturnMtrl" setText={setText} text={text}
                            errText="" validateIsErr={validateIsErr} maxLength="50" />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Step7