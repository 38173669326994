import "./textT1.css";
import { useEffect, useState } from "react";
import { FaInfoCircle } from "react-icons/fa";
import { Tooltip } from 'react-tooltip'

const TextBoxT1 = ({ placeholder, setText, text, headerText, jsonText,
  clsname = "", inputType = "text", sla = "", toolTip = "", disabled, validateIsErr, errText = "", minLength = "", maxLength = "" }) => {
  const [minTime, setMinTime] = useState("");
  //const [txtCss, setTxtCss] = useState(`txtBox2 ${clsname}`);
  const [txtCss, setTxtCss] = useState("txtBox2");

  function handleChange(evt) {
    setText({ ...text, [evt.target.name]: evt.target.value })
  }

  /*useEffect(() => {
    const runOnce = async () => {
      if (jsonText === "startTime") {
        setMinTime(sla.min_start_time);
      } else if (jsonText === "endTime") {
        setMinTime(sla.min_end_time);
      }
      //console.log(sla);
    }
    runOnce();
  }, [sla]);*/

  useEffect(() => {
    const errorCheck = async () => {
      if (validateIsErr[jsonText] === true) {
        setTxtCss(txtCss + " txtBox2_error");
      } else {
        setTxtCss(txtCss);
      }
    }
    errorCheck();
  }, [validateIsErr]);

  //useEffect(() => {
  //  console.log("MinTime: " + minTime);
  //}, []);
  function isError() {
    if (validateIsErr[jsonText] === true) {
      console.log("txtBox2_error");
      return "txtBox2_error";
    } else {
      return "";
    }
  }

  function isDisabled() {
    let ret = false;
    if (minTime === "" && (jsonText === "startTime" || jsonText === "endTime")) {
      ret = true;
    } else if (disabled) {
      ret = true;
    }
    else {
    }
    return ret;
  }
  return (
    <>
      <h6 className="txtH6">{headerText}{" "}
        <FaInfoCircle id={`info-circel-${jsonText}`} data-tooltip-content={toolTip} display={toolTip.length === 0 ? ("none") : ("")} />
        <Tooltip anchorId={`info-circel-${jsonText}`} multiline={true} data-html={true} className="infoCircelTop2" />
      </h6>
      <input type="text" placeholder={placeholder} className={(validateIsErr[jsonText] === true ? "txtBox2 txtBox2_error " : "txtBox2 ") + clsname} value={text[jsonText]}
        onChange={handleChange} name={jsonText} min={minTime}
        disabled={isDisabled()} minLength={minLength} maxLength={maxLength} /><br />
      <small className="errSmall">{validateIsErr[jsonText] === true ? errText : ""}</small>
    </>
  );
};

export default TextBoxT1;
