import "./dropDown.css";

const AgreementDropDownSelector = ({ headerText, ddData, agreementId, setAgreementId }) => {

    function handleChange(evt) {
        setAgreementId(evt.target.value);
    }

    const uniqueAgreements = [...new Set(ddData.map((item) => item.Agreements))];

    return (
        <>
            <h6 className="txtH6dd" style={{marginLeft: '0'}}>{headerText}</h6>
            <select onChange={handleChange} value={agreementId} >
                <option value="NA"> -- Alla -- </option>
                {uniqueAgreements.map((x) => (
                    <option key={x} value={x}>{x}</option>
                ))}
            </select><br />
        </>
    )
}

export default AgreementDropDownSelector