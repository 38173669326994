
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../../authConfig';
import { useState, useEffect } from "react";
import "./admin.css";
import Tabs from '../Tabs/Tabs';
import { FaInfoCircle } from "react-icons/fa";
import { Tooltip } from 'react-tooltip'
import Users from './Users';
import waitonCreate from "../../../assets/load.gif";

const MainAdmin = ({ setShowWAdmin }) => {
    const ApiDomain = process.env.REACT_APP_BACKEND_API_HOST;
    const { instance, accounts } = useMsal();
    const [isLoading, setIsLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [customerId, setCustomerId] = useState("");
    const [agreements, setAgreements] = useState("");
    const [msgText, setMsgText] = useState("");

    const saveData = (data) => {
        console.log("Savedata.");
        console.log(data);
        getInstance("UPDATE", data);
    };

    const deleteData = (data) => {
        console.log("Delete, 100");
        console.log(data);
        getInstance("DELETE", data);

    };

    const exitWindow = (e) => {
        setShowWAdmin(false);
    };

    const btnSendEmail = (e) => {
        setIsLoading(true);
        setMsgText("");
        getInstance("SENDINVITE", "");
    };

    useEffect(() => {
        getInstance("USERSETTINGS", "");
    }, [searchText])

    useEffect(() => {
        console.log("Users :");
        console.log(users);
    }, [users])

    function getInstance(type, data) {
        console.log(type);
        instance
            .acquireTokenSilent({
                ...loginRequest,
                account: accounts[0],
            })
            .then((response) => {
                if (type === "USERSETTINGS") {
                    myFetchUserSettings(response);
                } else if (type === "SENDINVITE") {
                    sendInvite(response);
                } else if (type === "UPDATE") {
                    updateUserSettings(response, data);
                } else if (type === "DELETE") {
                    deleteUserSettings(response, data);
                }
            })
            .catch((error) => {
                instance
                    .acquireTokenPopup({
                        ...loginRequest,
                        account: accounts[0],
                    })
                    .then((response) => {
                        if (type === "USERSETTINGS") {
                            myFetchUserSettings(response);
                        } else if (type === "SENDINVITE") {
                            sendInvite(response);
                        } else if (type === "UPDATE") {
                            updateUserSettings(response, data);
                        } else if (type === "DELETE") {
                            deleteUserSettings(response, data);
                        }
                        console.log(error);
                    });
            });
    }

    function sendInvite(response) {
        const headers = new Headers();
        const bearer = `Bearer ${response.accessToken}`;

        headers.append("Authorization", bearer);

        const options = {
            method: "GET",
            headers: headers
        };

        let url = "";
        url = `https://${ApiDomain}/b2c/b2c/invite?name=${name}&email=${email}&phone=${phone}`;
        console.log(`sendInvite URL: ${url}`);
        console.log(`ApiDomain: ${ApiDomain}`);
        const res = fetch(url, options).then((response) => {
            if (response.ok) {
                setMsgText("Inbjudning har skickats.")
            } else {
                console.log(response.status, response.statusText);
                setMsgText("Skicka inbjudning misslyckades. Kontakta support.")
            }
        }
        ).catch((error) => {
            console.log("error -->");
            console.log(error);
            setMsgText("Skicka inbjudning misslyckades. Kontakta support.")
        });
        console.log("res -->");
        console.log(res);

        addUserSettings(response);
        setIsLoading(false);
    }

    function addUserSettings(response) {
        const headers = new Headers();
        const bearer = `Bearer ${response.accessToken}`;

        headers.append("Authorization", bearer);

        const options = {
            method: "POST",
            headers: headers
        };

        let url = "";
        url = `https://${ApiDomain}/usersettings/user?userName=${email}&customerId=${customerId}&agreements=${agreements}&fullName=${name}`;
        console.log(`addUserSettings URL: ${url}`);
        console.log(`ApiDomain: ${ApiDomain}`);
        const res = fetch(url, options).then((response) => response.json()).then((data) => {
            console.log(data);
        }
        ).catch(error => console.log(error));
        console.log("res -->");
        console.log(res);
    }

    function updateUserSettings(response, data) {
        const headers = new Headers();
        const bearer = `Bearer ${response.accessToken}`;

        headers.append("Authorization", bearer);
        headers.append("Content-Type", "application/json");
        const options = {
            method: "POST",
            headers: headers,
            body: JSON.stringify(data)
        };

        let url = "";
        url = `https://${ApiDomain}/usersettings/user/update`;
        console.log(`updateUserSettings URL: ${url}`);
        console.log(`ApiDomain: ${ApiDomain}`);
        const res = fetch(url, options).then((response) => response.json()).then((data) => {
            console.log(data);
            getInstance("USERSETTINGS", "");
        }
        ).catch(error => console.log(error));
        console.log("res -->");
        console.log(res);
    }

    function deleteUserSettings(response, data) {
        const headers = new Headers();
        const bearer = `Bearer ${response.accessToken}`;

        headers.append("Authorization", bearer);
        headers.append("Content-Type", "application/json");
        const options = {
            method: "POST",
            headers: headers,
            body: JSON.stringify(data)
        };

        let url = "";
        url = `https://${ApiDomain}/usersettings/user/delete`;
        console.log(`deleteUserSettings URL: ${url}`);
        const res = fetch(url, options).then((response) => response.json()).then((data) => {
            console.log(data);
            getInstance("USERSETTINGS", "");
        }
        ).catch(error => console.log(error));
        console.log("res -->");
        console.log(res);
    }

    function myFetchUserSettings(response) {
        const headers = new Headers();
        const bearer = `Bearer ${response.accessToken}`;

        headers.append("Authorization", bearer);

        const options = {
            method: "GET",
            headers: headers
        };

        let url = "";
        url = `https://${ApiDomain}/UserSettings/get/users/settings?searchText=${searchText}`;
        console.log(`myFetchUserSettings URL: ${url}`);
        console.log(`ApiDomain: ${ApiDomain}`);
        const res = fetch(url, options).then((response) => response.json()).then((data) => {
            console.log(data);
            setUsers(data);
        }
        ).catch(error => console.log(error));
        console.log("res -->");
        console.log(res);
    }


    function checkValues() {
        let ret = false;
        if (phone === "" || name === "" || customerId === "" || agreements === "" || email === "") {
            ret = true;
        } else {
            if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
                ret = false;
            } else {
                ret = true;
            }
            if (/^\+?[0-9]*$/.test(phone)) {
                ret = false;
            } else {
                ret = true;
            }
            /*if (/^[0-9]{5}$/i.test(agreements)) {
                ret = false;
            } else {
                ret = true;
            }*/
        }
        return ret;
    }


    return (
        <div className='divMainWindow'>
            <div className="divMainHeader">
                <label className="labelMainStep">ADMIN SIDA</label>
            </div>
            <div className="divMainMiddle">
                <Tabs>
                    <div label="Användarinställningar">
                        <div className='divTab'>
                            Sök användare
                            <input type="text"
                                className='txtAdmin'
                                onChange={(e) => setSearchText(e.target.value)} />
                            <FaInfoCircle id={`userinfo`} data-tooltip-html="Lägg till nytt avtal genom att trycka på gröna plustecknet.<br />Ta bort rad görs på röda krysset." />
                            <Tooltip anchorId={`userinfo`} multiline={true} data-html={true} />
                            <Users users={users} setUsers={setUsers} saveData={saveData} deleteData={deleteData} />
                        </div>
                    </div>
                    <div label="Bjud in användare">
                        <div className='divTab'>
                            <table className="tableInvite">
                                <tbody>
                                    <tr>
                                        <td className="tdSpace">För och efternamn</td>
                                        <td className='tdRight tdSpace'>
                                            <input type="text"
                                                className='txtAdmin'
                                                onChange={(e) => setName(e.target.value)} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="tdSpace">
                                            Telefon{" "}
                                            <FaInfoCircle id={`phone`} data-tooltip-html="Användarens telefonnummer, <br />skriv i formatet 0701236547 eller +46701236547." />
                                            <Tooltip anchorId={`phone`} multiline={true} data-html={true} />
                                        </td>
                                        <td className='tdRight tdSpace'>
                                            <input type="text"
                                                className='txtAdmin'
                                                onChange={(e) => setPhone(e.target.value)} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="tdSpace">
                                            Email{" "}
                                            <FaInfoCircle id={`email`} data-tooltip-content="Användarens e-post adress." />
                                            <Tooltip anchorId={`email`} multiline={true} data-html={true} />
                                        </td>
                                        <td className='tdRight tdSpace'>
                                            <input type="text"
                                                className='txtAdmin'
                                                onChange={(e) => setEmail(e.target.value)} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="tdSpace">
                                            Kundnummer{" "}
                                            <FaInfoCircle id={`custno`} data-tooltip-content="Skriv in kundnummret från IFS." />
                                            <Tooltip anchorId={`custno`} multiline={true} data-html={true} />
                                        </td>
                                        <td className='tdRight tdSpace'>
                                            <input type="number"
                                                className='txtAdmin'
                                                onChange={(e) => setCustomerId(e.target.value)} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="tdSpace">
                                            Avtalsnummer{" "}
                                            <FaInfoCircle id={`agreements`} data-tooltip-content="Skriv in avtalsnummret från IFS." />
                                            <Tooltip anchorId={`agreements`} multiline={true} data-html={true} />
                                        </td>
                                        <td className='tdRight tdSpace'>
                                            <input type="text"
                                                className='txtAdmin'
                                                onChange={(e) => setAgreements(e.target.value)} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{" "}</td>
                                        <td className='tdRight tdSpace'>
                                            {
                                                isLoading ? (<img src={waitonCreate} alt="loading..." className="loadGifsmall" />) : (msgText)
                                            }{" "}
                                            <button className="btn" onClick={btnSendEmail} disabled={checkValues()}>
                                                Skicka inbjudan
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Tabs>
            </div>
            <div className="divMainFooter">
                <button className="btn btnEnd" onClick={exitWindow}>
                    Stäng
                </button>
            </div>
        </div>
    )
}

export default MainAdmin