import "./newWO.css";
import { useState, useEffect } from "react";
import Footer from "./Footer";
import FooterCreate from "./FooterCreate";
import Progressbar from "./Progressbar";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import Step6 from "./Step6";
import Step7 from "./Step7";
import Step8 from "./Step8";
import NewWoCreated from "./NewWoCreated";
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../../authConfig';
import { validate, formatDate } from "../../../components/pc/Utils.js"
import { v4 as uuidv4 } from 'uuid';
import { Modal, Button, Tab, Tabs, Row, Col } from 'react-bootstrap';

const NewWoMainWindow = ({ setShowCreateWO, customer_no, agreement, updateWoList, showSavedWorkOrders, setShowSavedWorkOrders }) => {
  const ApiDomain = process.env.REACT_APP_BACKEND_API_HOST;
  //const maxStep = 8;
  let numberOfFiles = 0;

  const [isBack, setIsBack] = useState(false);
  const [remValKommun, setRemValKommun] = useState("");
  const [sla, setSla] = useState({
    min_end_time: "",
    min_start_time: ""
  });
  const [wono, setWono] = useState("");
  const [loading, setLoading] = useState(false);
  const [addingFiles, setAddingFiles] = useState(false);
  const [remValLine, setRemValLine] = useState("");
  const [stepState, setStepState] = useState(1);
  const [stepCounter, setStepCounter] = useState(1);
  const [kommuner, setKommuner] = useState([]);
  const [produkter, setProdukter] = useState([]);
  const [formToShow, setFormToShow] = useState([]);
  const [files, setFiles] = useState({});
  const { instance, accounts } = useMsal();
  const [slaCreate, setSlaCreate] = useState({ start: "", end: "" });
  const [createWoError, setCreateWoError] = useState(false);
  const [chosenWo, setChosenWo] = useState({});

  const [newWoData, setNewWoData] = useState({
    kommun: "",
    product: "",
    startTime: "",
    endTime: "",
    streetAddress: "",
    key: "",
    keyid: "",
    customerName: "",
    customerPhone: "",
    customerAddress: "",
    customerPostalcode: "",
    customerCity: "",
    customerKeyID: "",
    customerKeyAddress: "",
    customerKeyName: "",
    customerKeyPhone: "",
    referensnummer: "",
    clientContact: accounts[0].name,
    clientPhone: accounts[0].idTokenClaims.mobile,
    siteID: "",
    siteAddress: "",
    sitePostalcode: "",
    siteCity: "",
    siteFloor: "",
    siteRow: "",
    accessSiteKeyID: "",
    accessSiteAddress: "",
    accessSitePostalcode: "",
    accessSiteCity: "",
    accessSiteName: "",
    accessSitePhone: "",
    longWorkDesc: "",
    lineNo: "",
    kobject: "",
    department: "",
    contract_id: "",
    maint_org: "",
    work_type_id: "",
    customer_no: customer_no,
    equipmentID: "",
    equipmentInterface: "",
    equipmentConnectionNumber: "",
    mtrlEquipment: "",
    sparePart1: "",
    sparePart2: "",
    sparePart3: "",
    mtrlReturnMtrl: "",
    infoMtrlReturnMtrl: "",
    siteExists: "",
    customerCompanyName: "",
    clientContactEmail: accounts[0].idTokenClaims.email,
    processState: "UNDER_CONSTUCTION",
    test1: "",
    test2: "",
    customerCallForAccess: "",
    accessSiteCallForAccess: "",
    transactionid: uuidv4(),
    shortDesc: "",
    savedId: -1
  });
  const [validateIsErr, setValidateIsErr] = useState({});
  let err = {
    kommun: false,
    product: false,
    startTime: false,
    endTime: false,
    streetAddress: false,
    key: false,
    keyid: false,
    customerName: false,
    customerPhone: false,
    customerAddress: false,
    customerPostalcode: false,
    customerCity: false,
    customerKeyID: false,
    customerKeyAddress: false,
    customerKeyName: false,
    customerKeyPhone: false,
    referensnummer: false,
    clientContact: false,
    clientPhone: false,
    siteID: false,
    siteAddress: false,
    sitePostalcode: false,
    siteCity: false,
    siteFloor: false,
    siteRow: false,
    accessSiteKeyID: false,
    accessSiteAddress: false,
    accessSitePostalcode: false,
    accessSiteCity: false,
    accessSiteName: false,
    accessSitePhone: false,
    longWorkDesc: false,
    lineNo: false,
    kobject: false,
    department: false,
    contract_id: false,
    maint_org: false,
    work_type_id: false,
    customer_no: customer_no,
    equipmentID: false,
    equipmentInterface: false,
    equipmentConnectionNumber: false,
    mtrlEquipment: false,
    sparePart1: false,
    sparePart2: false,
    sparePart3: false,
    mtrlReturnMtrl: false,
    infoMtrlReturnMtrl: false,
    siteExists: false,
    customerCompanyName: false,
    clientContactEmail: false,
    test1: false,
    test2: false,
    shortDesc: false
  };

  function getInstance(type, file = null, id = null) {
    console.log("getInstance type = " + type);
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        //callMsGraph(response.accessToken).then((response) => setGraphData(response));SAVEWO
        console.log(accounts[0]);
        if (type === "KOMMUN") {
          myFetchKommun(response);
        } else if (type === "PRODUKT") {
          myFetchProdukt(response);
        } else if (type === "CREATEWO") {
          myFetchcreateWO(response);
        } else if (type === "FILE") {
          uploadFiles(file, response);
        } else if (type === "PRODUKT_SLA") {
          return myFetchProduktSla(response);
        } else if (type === "SAVEWO") {
          saveWorkOrderToDb(response);
        } else if (type === "FORM_TO_SHOW") {
          myFetchFormsToShow(response);
        } else if (type === "DELETE_SAVED_WO") {
          deleteSavedWorkOrders(response, id);
        }
        //return response;
      })
      .catch((error) => {
        instance
          .acquireTokenPopup({
            ...loginRequest,
            account: accounts[0],
          })
          .then((response) => {
            //callMsGraph(response.accessToken).then((response) => setGraphData(response));
            if (type === "KOMMUN") {
              myFetchKommun(response);
            } else if (type === "PRODUKT") {
              myFetchProdukt(response);
            } else if (type === "CREATEWO") {
              myFetchcreateWO(response);
            } else if (type === "FILE") {
              uploadFiles(file, response);
            } else if (type === "PRODUKT_SLA") {
              return myFetchProduktSla(response);
            } else if (type === "SAVEWO") {
              saveWorkOrderToDb(response);
            } else if (type === "FORM_TO_SHOW") {
              myFetchFormsToShow(response);
            } else if (type === "DELETE_SAVED_WO") {
              deleteSavedWorkOrders(response, id);
            }
            console.log(error);
            //return response;
          });
      });
  }
  function deleteSavedWorkOrders(response, id) {
    const headers = new Headers();
    const bearer = `Bearer ${response.accessToken}`;
    headers.append("Authorization", bearer);
    const options = {
      method: "DELETE",
      headers: headers
    };
    let url = `https://${ApiDomain}/UserSaveWorkOrder/delete/${id}`;
    fetch(url, options).then(function (response) {
      if (!response.ok) {
        console.log("Fel vid borttagning av wo.");
      }
    }).catch((error) => {
      console.log(error);
    });
  }
  async function fetchWithTimeout(resource, options = {}, file) {
    const { timeout = 80000 } = options;

    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);

    const formData = new FormData();
    formData.append("fileUpload", file);

    const response = await fetch(resource, {
      ...options,
      signal: controller.signal,
      method: "POST",
      body: formData,
    });
    clearTimeout(id);
    return response;
  }

  async function uploadFiles(file, responseToken) {
    try {
      //await delay(i);
      console.log(file);
      console.log("uploadFiles wono = " + wono);
      if (wono.length !== 8) {
        return;
      }
      const headers = new Headers();
      const bearer = `Bearer ${responseToken.accessToken}`;
      headers.append("Authorization", bearer);
      var url = `https://${ApiDomain}/Ora/ora/Files/Uplod/singel?wono=${wono}`;
      console.log(`add attachments URL: ${url}`);
      let response = await fetchWithTimeout(
        url,
        {
          headers: headers,
          timeout: 300000,
        }, file
      );
      const returnVal = await response.json();
      if (files.length === numberOfFiles) {
        setAddingFiles(false);
      }
      return returnVal;
    } catch (error) {
      if (files.length === numberOfFiles) {
        setAddingFiles(false);
      }
      console.log(`error =  ${error}`);
      console.log(error.name === "AbortError");
      return "";
    }
  }

  function delayFile(file, i) {
    setTimeout(function () {
      console.log(i);
      //uploadFiles(file);
      getInstance("FILE", file);
    }, 1000 * i);
  }
  //Start för att kolla vid skapande om sla har gått ut
  //Behöver nytt api för dessa
  function checkStartEndTime() {
    console.log("*** checkStartEndTime ***");
    if (newWoData.startTime !== "" || newWoData.endTime !== "") {
      setNewWoData({ ...newWoData, processState: "DONE" })
      getInstance("PRODUKT_SLA");
    } else {
      console.log("CRE WO 4");
      getInstance("CREATEWO");
    }
  }
  useEffect(() => {
    const checkSLA = async () => {
      console.log(`stepState: ${stepState}`);
      if (newWoData.kobject === "" || newWoData.lineNo === "") {
        return;
      }
      if (newWoData.test2 === "DONE" || newWoData.processState === "UNDER_CONSTUCTION") {
        return;
      }
      if (newWoData.test2 === "SLACHANGE") {
        console.log("CRE WO 1");
        getInstance("CREATEWO");
        setNewWoData({ ...newWoData, test2: "DONE" })
      } else {
        if (newWoData.startTime === "" && newWoData.endTime === "") {
          console.log("CRE WO 5");
          getInstance("CREATEWO");
        } else if (newWoData.startTime !== "" && newWoData.endTime === "") { //Start
          if (slaCreate.start !== "") {
            console.log(`newWoData.startTime: ${newWoData.startTime}`);
            console.log(`slaCreate.start: ${slaCreate.start}`);
            if (newWoData.startTime.replace("T", " ") < slaCreate.start) {
              if (window.confirm("Starttid kommer ändras till " + slaCreate.start + " är det ok?")) {
                console.log("Starttid OK");
                setNewWoData({ ...newWoData, startTime: slaCreate.start, test2: "SLACHANGE" });
              } else {
                window.alert("Order kommer inte kunna skapas med denna SLA tid.");
                console.log("Starttid Cancel");
              }
            } else {
              console.log("CRE WO 2");
              getInstance("CREATEWO");
            }
          } else {
            console.log("slaCreate.start is empty");
          }
        } else if (newWoData.startTime === "" && newWoData.endTime !== "") { //End
          if (slaCreate.end !== "") {
            if (newWoData.endTime.replace("T", " ") < slaCreate.end) {
              if (window.confirm("Sluttid kommer ändras till " + slaCreate.end + " är det ok?")) {
                console.log("Sluttid OK");
                setNewWoData({ ...newWoData, endTime: slaCreate.end, test2: "SLACHANGE" });
              } else {
                window.alert("Order kommer inte kunna skapas med denna SLA tid.");
                console.log("Sluttid Cancel");
              }
            } else {
              console.log("CRE WO 3");
              getInstance("CREATEWO");
            }
          } else {
            console.log("slaCreate.end is empty");
          }
        } else if (newWoData.startTime !== "" && newWoData.endTime !== "") { //Start and End
          let isCre = false;
          let isChange = false;
          if (slaCreate.start !== "") {
            if (newWoData.startTime.replace("T", " ") < slaCreate.start) {
              if (window.confirm("Starttid kommer ändras till " + slaCreate.start + " är det ok?")) {
                console.log("Starttid OK");
                setNewWoData({ ...newWoData, startTime: slaCreate.start, test2: "SLACHANGE" });
                isChange = true;
              } else {
                window.alert("Order kommer inte kunna skapas med denna SLA tid.");
                console.log("Starttid Cancel");
              }
            } else {
              isCre = true;
            }
          } else {
            console.log("slaCreate.start is empty");
          }
          if (slaCreate.end !== "") {
            if (newWoData.endTime.replace("T", " ") < slaCreate.end) {
              if (window.confirm("Sluttid kommer ändras till " + slaCreate.end + " är det ok?")) {
                console.log("Sluttid OK");
                setNewWoData({ ...newWoData, endTime: slaCreate.end, test2: "SLACHANGE" })
                isChange = true;
              } else {
                window.alert("Order kommer inte kunna skapas med denna SLA tid.");
                console.log("Sluttid Cancel");
              }
            } else {
              isCre = true;
            }
          } else {
            console.log("slaCreate.end is empty");
          }
          if (isCre === true && isChange === false) {
            console.log("CRE WO 6");
            getInstance("CREATEWO");
          }
        }
      }
    }
    checkSLA();
  }, [slaCreate, newWoData.test2]);

  const saveWO = async () => {
    console.log("** saveWO **");
    if (window.confirm(`Du kommer spara eller uppdatera en version av denna 
    arbetsorder med namn ${newWoData.shortDesc} 
    för att fortsätta att arbeta med den senare.
    Vill du det?`)) {
      if (files.length > 0) {
        window.alert(`Observera att filerna du lagt på ordern inte kommer sparas. 
          Dessa kommer du få lägga till igen när ordern ska skapas.`)
      }
      getInstance("SAVEWO");
    }
  }

  const saveWorkOrderToDb = async (responseToken) => {
    console.log(`saveWorkOrderToDb`);
    let copyOfnewWoData = newWoData;
    if (copyOfnewWoData.test2 === "DONE") {
      copyOfnewWoData = { ...copyOfnewWoData, test2: "" };
    }
    console.log("copyOfnewWoData--->");
    console.log(copyOfnewWoData);
    const headers = new Headers();
    const bearer = `Bearer ${responseToken.accessToken}`;
    headers.append("Authorization", bearer);
    headers.append("Content-Type", "application/json");
    const options = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(copyOfnewWoData)
    };
    let name = newWoData.shortDesc;
    let url = `https://${ApiDomain}/UserSaveWorkOrder/save?saveName=${name}`;
    console.log(`saveWO URL: ${url}`);
    const response = await fetch(url, options);

    if (response.ok) {
      console.log("Success!");
      window.alert("Arbetsorder utkast sparad!");
    } else {
      console.log("Fail to save!");
      window.alert("Misslyckades att spara arbetsorder utkast!");
    }
  };

  const createWO = async () => {
    console.log("** createWO **");
    checkStartEndTime();
    //getInstance("CREATEWO");
  }

  const myFetchcreateWO = async (responseToken) => {
    console.log(`myFetchcreateWO`);

    setLoading(true);
    const headers = new Headers();
    const bearer = `Bearer ${responseToken.accessToken}`;
    headers.append("Authorization", bearer);
    headers.append("Content-Type", "application/json");
    const options = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(newWoData)
    };
    console.log(`newWoData =  ${JSON.stringify(newWoData)}`);
    let url = `https://${ApiDomain}/Ora/ora/post/create/wo`;
    console.log(`createWO URL: ${url}`);
    const response = await fetch(url, options);

    if (!response.ok) {
      setCreateWoError(true);
    }
    else {
      const data = await response.json();
      console.log(`MSG: ${data.Message}`);
      if (data.IsOk) {
        console.log("Success!");
        setWono(data.Message);
      } else {
        console.log("Error " + data.Error);
        setWono(data.Message);
        //setLoading(false);
      }
    }
  };

  useEffect(() => {
    let i = 0;
    if (files.length > 0 && wono.length === 8) {
      numberOfFiles = files.length;
      setAddingFiles(true);
      Array.from(files).forEach(file => {
        i += 1;
        console.log(`Call 1 ${i} uploadFiles: ${file}`);
        delayFile(file, i);
      });
    }
    if (newWoData.savedId !== -1 && wono.length === 8) {
      getInstance("DELETE_SAVED_WO", null, newWoData.savedId);
    }
  }, [wono]);

  useEffect(() => {
    setRemValLine('');
    setNewWoData({
      ...newWoData,
      lineNo: "",
      contract_id: "",
      maint_org: "",
      work_type_id: "",
      product: "",
      startTime: "",
      endTime: "",
      shortDesc: ""
    });
    setSla({
      min_end_time: "",
      min_start_time: ""
    });
  }, [remValKommun])

  useEffect(() => {
    console.log(`loading: ${loading}`);
  }, [loading])

  useEffect(() => {
    getInstance("FORM_TO_SHOW");
  }, [newWoData.product])

  useEffect(() => {
    const getKommun = async () => {
      getInstance("KOMMUN");
    }
    getKommun();
  }, []);

  function myFetchKommun(response) {
    const headers = new Headers();
    const bearer = `Bearer ${response.accessToken}`;
    headers.append("Authorization", bearer);
    const options = {
      method: "GET",
      headers: headers
    };
    let url = "";
    url = `https://${ApiDomain}/Ora/ora/get/kommuner?customer_id=${customer_no}&agreement_id=${agreement}`;
    fetch(url, options).then((response) => response.json()).then((data) => {
      console.log(data);
      setKommuner(data);
    }
    ).catch(error => console.log(error));
  }

  useEffect(() => {
    const getProdukt = async () => {
      console.log(`newWoData.kobject.length: ${newWoData.kobject.length}`);
      if (newWoData.kobject.length !== 0) {
        getInstance("PRODUKT");
      }
    }
    getProdukt();
  }, [newWoData.kobject]);

  function myFetchProdukt(response) {
    const headers = new Headers();
    const bearer = `Bearer ${response.accessToken}`;
    headers.append("Authorization", bearer);
    const options = {
      method: "GET",
      headers: headers
    };
    let url = "";
    url = `https://${ApiDomain}/Ora/ora/get/styrdata?mch_code=${newWoData.kobject}&customer_id=${customer_no}&agreement=${agreement}`;
    console.log(`myFetchProdukt URL: ${url}`);
    fetch(url, options).then((response) => response.json()).then((data) => {
      //console.log(data);
      setProdukter(data);
    }
    ).catch(error => console.log(error));
  }

  function myFetchFormsToShow(response) {
    const headers = new Headers();
    const bearer = `Bearer ${response.accessToken}`;
    headers.append("Authorization", bearer);
    const options = {
      method: "GET",
      headers: headers
    };
    let url = "";
    url = `https://${ApiDomain}/DynamicForm/form?customerId=${customer_no}&agreementId=${agreement}&productId=${newWoData.work_type_id}`; //SSE_FIBER2S, product
    console.log(`myFetchFormsToShow URL: ${url}`);
    fetch(url, options).then((response) => response.json()).then((data) => {
      console.log(data);
      setFormToShow(data);
    }
    ).catch(error => console.log(error));
  }

  function myFetchProduktSla(response) {
    const headers = new Headers();
    const bearer = `Bearer ${response.accessToken}`;
    headers.append("Authorization", bearer);
    const options = {
      method: "GET",
      headers: headers
    };
    let url = "";
    url = `https://${ApiDomain}/Ora/ora/get/styrdata?mch_code=${newWoData.kobject}&customer_id=${customer_no}&agreement=${agreement}`;
    console.log(`myFetchProduktSla URL: ${url}`);
    fetch(url, options).then((response) => response.json()).then((data) => {
      let d = data;
      d.map(({ line_no, min_start_time, min_end_time }) => {
        if (line_no === newWoData.lineNo) {
          console.log("Hit:" + min_start_time);
          setSlaCreate({ ...slaCreate, start: min_start_time, end: min_end_time });
        }
      })
      //setProdukter(data);
    }
    ).catch(error => console.log(error));
  }

  const exitWindow = async () => {
    setShowCreateWO(false);
  };

  //const stepToShow = [true, true, false, false, true, true, true, true];
  const maxStep = formToShow.filter(item => item.IsActivated === true).length;
  const nextWindow = async () => {
    setIsBack(false);
    if (validateFields() === false) {
      if (formToShow[stepState].IsActivated) {
        setStepState(stepState + 1);
      } else {
        let nextStep = stepState + 1;
        while (nextStep < formToShow.length && !formToShow[nextStep - 1].IsActivated) {
          nextStep++;
        }
        setStepState(nextStep);
      }
      setStepCounter(stepCounter + 1);
    }
  };

  const backWindow = async () => {
    setIsBack(true);
    if (formToShow[stepState - 2].IsActivated) {
      setStepState(stepState - 1);
    } else {
      let nextStep = stepState - 1;
      while (nextStep < formToShow.length && !formToShow[nextStep - 1].IsActivated) {
        nextStep--;
      }
      setStepState(nextStep);
    }
    setStepCounter(stepCounter - 1);
  };

  useEffect(() => {
    let localChosenWo = chosenWo;
    if ("referensnummer" in localChosenWo) {
      localChosenWo = { ...localChosenWo, transactionid: uuidv4() };
      setNewWoData(localChosenWo);
    }
  }, [chosenWo]);

  useEffect(() => {
    //console.log(newWoData);
    if (newWoData.kommun !== "" && newWoData.product !== "" && newWoData.shortDesc === "") {
      setNewWoData({
        ...newWoData,
        shortDesc: newWoData.kommun + " - " + newWoData.product
      });
    }
  }, [newWoData]);

  /*useEffect(() => {
    console.log(produkter);
  }, [produkter]);

  useEffect(() => {
    console.log(files);
  }, [files]);

  useEffect(() => {
    console.log("validateIsErr:");
    console.log(validateIsErr);
  }, [validateIsErr]);*/

  function validateFields() {
    let ret = false;
    //Step1
    validate(newWoData, err);
    if (stepState === 1) {
      if (newWoData.kobject === "") {
        err = { ...err, kobject: true };
        ret = true;
      } else {
        err = { ...err, kobject: false };
      }

      if (newWoData.product === "") {
        err = { ...err, product: true };
        ret = true;
      } else {
        err = { ...err, product: false };
      }

      if (newWoData.shortDesc === "") {
        err = { ...err, shortDesc: true };
        ret = true;
      } else {
        err = { ...err, shortDesc: false };
      }

      if (newWoData.startTime === "" && newWoData.endTime === "") {
        if (!window.confirm("Du har ej angivet start eller stopptid för din arbetsorder. " +
          "Utan önskad datumangivelse för start eller stopp kan skapad arbetsorder inte " +
          "prioriteras i enlighet med avtalad SLA. Vill du fortsätta ändå?")) {
          ret = true;
        }
      }

      if (newWoData.startTime !== "" && sla.min_start_time !== "" && sla.min_start_time !== null) {
        if (newWoData.startTime.replace("T", " ") < sla.min_start_time) {
          alert("Datumet är tidigare än SLA tid: " + sla.min_start_time);
          err = { ...err, startTime: true };
          ret = true;
        } else {
          err = { ...err, startTime: false };
        }
      } else if (newWoData.startTime !== "" && sla.min_start_time === null) {
        if (newWoData.startTime.replace("T", " ") < formatDate(new Date())) {
          alert("Start datumet är tidigare än nu: " + newWoData.startTime.replace("T", " "));
          err = { ...err, startTime: true };
          ret = true;
        } else {
          err = { ...err, startTime: false };
        }
      } else {
        err = { ...err, startTime: false };
      }

      if (newWoData.endTime !== "" && sla.min_end_time !== "" && sla.min_end_time !== null) {
        if (newWoData.endTime.replace("T", " ") < sla.min_end_time) {
          alert("Datumet är tidigare än SLA tid: " + sla.min_end_time);
          err = { ...err, endTime: true };
          ret = true;
        } else {
          err = { ...err, endTime: false };
        }
      } else if (newWoData.endTime !== "" && sla.min_end_time === null) {
        if (newWoData.endTime.replace("T", " ") < formatDate(new Date())) {
          alert("Slut datumet är tidigare än nu: " + newWoData.endTime.replace("T", " "));
          err = { ...err, endTime: true };
          ret = true;
        } else {
          err = { ...err, endTime: false };
        }
      } else {
        err = { ...err, endTime: false };
      }

      if (newWoData.startTime !== "" && newWoData.endTime !== "" && err.startTime === false && err.endTime === false) {
        if (newWoData.startTime.replace("T", " ") >= newWoData.endTime.replace("T", " ")) {
          alert("Startdatum är senare slutdatum:\n" + newWoData.startTime.replace("T", " ") + " är större än " + newWoData.endTime.replace("T", " "));
          err = { ...err, startTime: true, endTime: true };
          ret = true;
        } else {
          err = { ...err, startTime: false, endTime: false };
        }
      }

      if (newWoData.clientContact === "") {
        err = { ...err, clientContact: true };
        ret = true;
      } else {
        err = { ...err, clientContact: false };
      }

      if (!/^[A-Za-z0-9ÅÄÖåäö,-]*$/.test(newWoData.referensnummer) || newWoData.referensnummer === "") {
        err = { ...err, referensnummer: true };
        ret = true;
      } else {
        err = { ...err, referensnummer: false };
      }

      if (newWoData.clientContactEmail !== "") {
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(newWoData.clientContactEmail)) {
          err = { ...err, clientContactEmail: true };
          ret = true;
        } else {
          err = { ...err, clientContactEmail: false };
        }
      } else {
        err = { ...err, clientContactEmail: true };
        ret = true;
      }

      if (newWoData.clientPhone === "") {
        err = { ...err, clientPhone: true };
        ret = true;
      } else {
        if (!/^\+?[0-9]*$/.test(newWoData.clientPhone)) {
          err = { ...err, clientPhone: true };
          ret = true;
        } else {
          err = { ...err, clientPhone: false };
        }
      }
    } else if (stepState === 2) {   //Step2
      if (newWoData.customerName === "") {
        err = { ...err, customerName: true };
        ret = true;
      } else {
        err = { ...err, customerName: false };
      }
      if (newWoData.customerPhone === "") {
        err = { ...err, customerPhone: true };
        ret = true;
      } else {
        err = { ...err, customerPhone: false };
      }
      if (newWoData.customerAddress === "") {
        err = { ...err, customerAddress: true };
        ret = true;
      } else {
        err = { ...err, customerAddress: false };
      }
      if (!/^[0-9]{3}\s?[0-9]{2}$/.test(newWoData.customerPostalcode)) {
        err = { ...err, customerPostalcode: true };
        ret = true;
      } else {
        err = { ...err, customerPostalcode: false };
      }
      if (newWoData.customerCity === "") {
        err = { ...err, customerCity: true };
        ret = true;
      } else {
        err = { ...err, customerCity: false };
      }
    } else if (stepState === 3) {   //Step3
      if (newWoData.customerKeyPhone === "") {
        err = { ...err, customerKeyPhone: false };
      } else {
        if (!/^\+?[0-9]*$/.test(newWoData.customerKeyPhone)) {
          err = { ...err, customerKeyPhone: true };
          ret = true;
        } else {
          err = { ...err, customerKeyPhone: false };
        }
      }
    } else if (stepState === 4) {   //Step4
      if (newWoData.siteExists === "") {
        err = { ...err, siteExists: true };
        ret = true;
      } else if (newWoData.siteExists === "No") {
        err = { ...err, siteExists: false };
      } else if (newWoData.siteExists === "Yes") {
        err = { ...err, siteExists: false };
        if (newWoData.siteID === "") {
          err = { ...err, siteID: true };
          ret = true;
        } else {
          err = { ...err, siteID: false };
        }

        if (newWoData.siteAddress === "") {
          err = { ...err, siteAddress: true };
          ret = true;
        } else {
          err = { ...err, siteAddress: false };
        }

        if (!/^[0-9]{3}\s?[0-9]{2}$/.test(newWoData.sitePostalcode)) {
          err = { ...err, sitePostalcode: true };
          ret = true;
        } else {
          err = { ...err, sitePostalcode: false };
        }

        if (newWoData.siteCity === "") {
          err = { ...err, siteCity: true };
          ret = true;
        } else {
          err = { ...err, siteCity: false };
        }

        if (newWoData.siteFloor === "") {
          err = { ...err, siteFloor: true };
          ret = true;
        } else {
          err = { ...err, siteFloor: false };
        }

        if (newWoData.siteRow === "") {
          err = { ...err, siteRow: true };
          ret = true;
        } else {
          err = { ...err, siteRow: false };
        }
      }
    } else if (stepState === 5) {   //Step5
      if (newWoData.accessSitePhone === "") {
        err = { ...err, accessSitePhone: false };
      } else {
        if (!/^\+?[0-9]*$/.test(newWoData.accessSitePhone)) {
          err = { ...err, accessSitePhone: true };
          ret = true;
        } else {
          err = { ...err, accessSitePhone: false };
        }
      }

      if (newWoData.accessSitePostalcode === "") {
        err = { ...err, accessSitePostalcode: false };
      } else {
        if (!/^[0-9]{3}\s?[0-9]{2}$/.test(newWoData.accessSitePostalcode)) {
          err = { ...err, accessSitePostalcode: true };
          ret = true;
        } else {
          err = { ...err, accessSitePostalcode: false };
        }
      }
    }
    setValidateIsErr(err);
    return ret;
  }

  function checkNextBtnDisabled_1() {
    let ret = false;
    return ret;
  }

  function checkNextBtnDisabled_2() {
    let ret = false;
    return ret;
  }

  function checkNextBtnDisabled_3() {
    let ret = false;
    return ret;
  }

  function checkNextBtnDisabled_4() {
    let ret = false;
    return ret;
  }

  function checkNextBtnDisabled_5() {
    let ret = false;
    return ret;
  }

  function checkNextBtnDisabled_6() {
    let ret = false;
    return ret;
  }

  function checkNextBtnDisabled_7() {
    let ret = false;
    return ret;
  }

  function checkNextBtnDisabled_8() {
    let ret = false;
    if (wono.length === 8) {
      ret = true;
    }
    return ret;
  }

  function checkNextInputFieldsDisabled_4() {
    let ret = false;
    if (newWoData.siteExists === "") {
      ret = true;
    } else if (newWoData.siteExists === "Yes") {
      ret = false;
    } else if (newWoData.siteExists === "No") {
      ret = true;
    }
    return ret;
  }

  switch (stepState) {
    case 1:
      return (
        <Modal show={true} size="lg" fullscreen={"lg-down"} className={`modalDialogNewWo ${showSavedWorkOrders !== 0 ? " modalDialogNewWoOpenWoOpen" : ""}`}>
          <Modal.Header className="modal-header divHeader">
            <div className="header_div">
              <p className="labelStep">Steg {stepCounter} av {maxStep}</p>
              <Progressbar step={stepCounter} maxStep={maxStep} />
            </div>
          </Modal.Header>
          <Modal.Body className="divExtMiddle">
            <Step1 setText={setNewWoData} text={newWoData} ddKommun={kommuner} ddProdukt={produkter}
              remValKommun={remValKommun} setRemValKommun={setRemValKommun} remValLine={remValLine} setRemValLine={setRemValLine}
              sla={sla} setSla={setSla} validateIsErr={validateIsErr} isBack={isBack} setIsBack={setIsBack} />
          </Modal.Body>
          <Modal.Footer>
            <Footer
              nextWindow={nextWindow}
              exitWindow={exitWindow}
              backWindow={backWindow}
              stepState={stepState}
              btnDisabled={checkNextBtnDisabled_1()}
              setChosenWo={setChosenWo}
              showSavedWorkOrders={showSavedWorkOrders}
              setShowSavedWorkOrders={setShowSavedWorkOrders}
            />
          </Modal.Footer>
        </Modal>
      );
    case 2:
      return (
        <Modal show={true} size="lg" fullscreen={"lg-down"} className="modalDialog">
          <Modal.Header className="modal-header divHeader">
            <div className="header_div">
              <p className="labelStep">Steg {stepCounter} av {maxStep}</p>
              <Progressbar step={stepCounter} maxStep={maxStep} />
            </div>
          </Modal.Header>
          <Modal.Body className="divExtMiddle">
            <Step2 setText={setNewWoData} text={newWoData} validateIsErr={validateIsErr} />
          </Modal.Body>
          <Modal.Footer>
            <Footer
              nextWindow={nextWindow}
              exitWindow={exitWindow}
              backWindow={backWindow}
              stepState={stepState}
              btnDisabled={checkNextBtnDisabled_2()}
              saveWO={saveWO}
            />
          </Modal.Footer>
        </Modal>
      );
    case 3:
      return (
        <Modal show={true} size="lg" fullscreen={"lg-down"} className="modalDialog">
          <Modal.Header className="modal-header divHeader">
            <div className="header_div">
              <p className="labelStep">Steg {stepCounter} av {maxStep}</p>
              <Progressbar step={stepCounter} maxStep={maxStep} />
            </div>
          </Modal.Header>
          <Modal.Body className="divExtMiddle">
            <Step3 setText={setNewWoData} text={newWoData} validateIsErr={validateIsErr} />
          </Modal.Body>
          <Modal.Footer>
            <Footer
              nextWindow={nextWindow}
              exitWindow={exitWindow}
              backWindow={backWindow}
              stepState={stepState}
              btnDisabled={checkNextBtnDisabled_3()}
              saveWO={saveWO}
            />
          </Modal.Footer>
        </Modal>
      );
    case 4:
      return (
        <Modal show={true} size="lg" fullscreen={"lg-down"} className="modalDialog">
          <Modal.Header className="modal-header divHeader">
            <div className="header_div">
              <p className="labelStep">Steg {stepCounter} av {maxStep}</p>
              <Progressbar step={stepCounter} maxStep={maxStep} />
            </div>
          </Modal.Header>
          <Modal.Body className="divExtMiddle">
            <Step4 setText={setNewWoData} text={newWoData} disabled={checkNextInputFieldsDisabled_4()} validateIsErr={validateIsErr} />
          </Modal.Body>
          <Modal.Footer>
            <Footer
              nextWindow={nextWindow}
              exitWindow={exitWindow}
              backWindow={backWindow}
              stepState={stepState}
              btnDisabled={checkNextBtnDisabled_4()}
              saveWO={saveWO}
            />
          </Modal.Footer>
        </Modal>
      );
    case 5:
      return (
        <Modal show={true} size="lg" fullscreen={"lg-down"} className="modalDialog">
          <Modal.Header className="modal-header divHeader">
            <div className="header_div">
              <p className="labelStep">Steg {stepCounter} av {maxStep}</p>
              <Progressbar step={stepCounter} maxStep={maxStep} />
            </div>
          </Modal.Header>
          <Modal.Body className="divExtMiddle">
            <Step5 setText={setNewWoData} text={newWoData} validateIsErr={validateIsErr} />
          </Modal.Body>
          <Modal.Footer>
            <Footer
              nextWindow={nextWindow}
              exitWindow={exitWindow}
              backWindow={backWindow}
              stepState={stepState}
              btnDisabled={checkNextBtnDisabled_5()}
              saveWO={saveWO}
            />
          </Modal.Footer>
        </Modal>
      );
    case 6:
      return (
        <Modal show={true} size="lg" fullscreen={"lg-down"} className="modalDialog">
          <Modal.Header className="modal-header divHeader">
            <div className="header_div">
              <p className="labelStep">Steg {stepCounter} av {maxStep}</p>
              <Progressbar step={stepCounter} maxStep={maxStep} />
            </div>
          </Modal.Header>
          <Modal.Body className="divExtMiddle">
            <Step6 setText={setNewWoData} text={newWoData} validateIsErr={validateIsErr} />
          </Modal.Body>
          <Modal.Footer>
            <Footer
              nextWindow={nextWindow}
              exitWindow={exitWindow}
              backWindow={backWindow}
              stepState={stepState}
              btnDisabled={checkNextBtnDisabled_6()}
              saveWO={saveWO}
            />
          </Modal.Footer>
        </Modal>
      );
    case 7:
      return (
        <Modal show={true} size="lg" fullscreen={"lg-down"} className="modalDialog">
          <Modal.Header className="modal-header divHeader">
            <div className="header_div">
              <p className="labelStep">Steg {stepCounter} av {maxStep}</p>
              <Progressbar step={stepCounter} maxStep={maxStep} />
            </div>
          </Modal.Header>
          <Modal.Body className="divExtMiddle">
            <Step7 setText={setNewWoData} text={newWoData} validateIsErr={validateIsErr} />
          </Modal.Body>
          <Modal.Footer>
            <Footer
              nextWindow={nextWindow}
              exitWindow={exitWindow}
              backWindow={backWindow}
              stepState={stepState}
              btnDisabled={checkNextBtnDisabled_7()}
              saveWO={saveWO}
            />
          </Modal.Footer>
        </Modal>
      );
    case 8:
      return (
        <Modal show={true} size="lg" fullscreen={"lg-down"} className="modalDialog">
          <Modal.Header className="modal-header divHeader">
            <div className="header_div">
              <p className="labelStep">Steg {stepCounter} av {maxStep}</p>
              <Progressbar step={stepCounter} maxStep={maxStep} />
            </div>
          </Modal.Header>
          <Modal.Body className="divExtMiddle">
            <Step8 setText={setNewWoData} text={newWoData} files={files} setFiles={setFiles} />
            {
              loading ? (
                <NewWoCreated wono={wono} setLoading={setLoading} setWono={setWono}
                  updateWoList={updateWoList} addingFiles={addingFiles} exitWindow={exitWindow} createWoError={createWoError} setCreateWoError={setCreateWoError} createWO={createWO} />
              ) : (<></>)
            }
          </Modal.Body>
          <Modal.Footer>
            <FooterCreate
              exitWindow={exitWindow}
              backWindow={backWindow}
              stepState={stepState}
              createWO={createWO}
              btnDisabled={checkNextBtnDisabled_8()}
              saveWO={saveWO}
            />
          </Modal.Footer>
        </Modal>
      );
    default:
      return (
        <div className="divNewWO">
          {" "}
          <div className="divMiddle">Error</div>
        </div>
      );
  }
};

export default NewWoMainWindow;
