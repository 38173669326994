import React from 'react'
import { statusMappings, fixDateFormat, colorMappings } from "../../../components/pc/Utils.js"
import LabelExistingWo from '../controls/LabelExistingWo.js';
import TextBoxExistingWo from '../controls/TextBoxExistingWo.js';
import { useState, useEffect } from "react";
import { FaEdit, FaRegTrashAlt } from "react-icons/fa";
import { Tooltip } from 'react-tooltip';
import waitonCreate from "../../../assets/load.gif";
import { Table } from 'react-bootstrap';

const TabOverView = ({ woDetails, woUpdateData, setWoUpdateData, saveUpdate, user = "", setUpdateMsg, updateMsg, setIsUpdating, isUpdating, getParameterValue }) => {
    const [isEdit, setIsEdit] = useState(false);
    const [isCancel, setIsCancel] = useState(false);
    const [cancelText, setCancelText] = useState("");
    const [isErr, setIsErr] = useState(false);
    function validate() {
        let ret = false;
        if (!/^\+?[0-9]*$/.test(woUpdateData.clientPhone)) {
            setIsErr(true);
            ret = true;
        } else {
            setIsErr(false);
        }
        return ret;
    }
    const edit = (e) => {
        setIsEdit(!isEdit);
        setUpdateMsg({ ...updateMsg, isOk: false, message: "" });
    };

    const cancel = (e) => {
        setIsCancel(!isCancel);
    };

    const saveWo = (e) => {
        console.log("Save");
        if (validate() === false) {
            setIsUpdating(true);
            setUpdateMsg({ ...updateMsg, isOk: false, message: "" });
            saveUpdate();
        }
    };

    useEffect(() => {
        console.log(woUpdateData);
        if (woUpdateData.test1 === "CANCEL") {
            saveUpdate();
            setWoUpdateData({
                ...woUpdateData, test1: ""
            })
        }
    }, [woUpdateData]);

    const saveCancelWo = (e) => {
        console.log("SaveCancel");
        console.log("Save");
        let date = new Date().toLocaleString() + "";
        let tmp = "Makulering av " + user + " - " + date + "\n\r" + cancelText;
        let workDescr = tmp + "\n\r\n\r" + woDetails[0].WORK_DESCR_LO;
        setWoUpdateData({
            ...woUpdateData,
            cancelReason: tmp,
            longWorkDesc: workDescr,
            test1: "CANCEL",
            messageType: "CANCEL"
        })
    };

    function cancelReasonChange(evt) {
        //setWoUpdateData({ ...woUpdateData, longWorkDesc: evt.target.value })
        setCancelText(evt.target.value);
    }

    if (isEdit) {
        return (
            <>
                <Table responsive="lg" className='table1' borderless="true">
                    <tbody>
                        <tr>
                            <td colSpan={2}>
                                <FaEdit id="faediticon" data-tooltip-content="Ändra data" onClick={edit} className="faEdit" />
                                <Tooltip anchorId="faediticon" multiline={true} data-html={true} />
                            </td>
                        </tr>
                        <tr>
                            <td><LabelExistingWo text={fixDateFormat(woDetails[0].REG_DATE)} headerText="Skapad" jsonText="createdtime" /></td>
                            <td><LabelExistingWo text={woDetails[0].REFERENCE_NO} headerText="Referensnummer" jsonText="refnr" /></td>
                        </tr>
                        <tr>
                            <td>
                                <LabelExistingWo text={statusMappings(woDetails[0].OBJSTATE, getParameterValue("9090"), woDetails[0].KUND_MAK)}
                                    headerText="Status" jsonText="state" css={colorMappings(woDetails[0].OBJSTATE, getParameterValue("9090"), woDetails[0].KUND_MAK)} />
                            </td>
                            <td>{" "}</td>
                        </tr>
                        <tr>
                            <td><LabelExistingWo text={woDetails[0].KOMMUN} headerText="Kommun" jsonText="kommun" /></td>
                            <td><LabelExistingWo text={getParameterValue("9801")} headerText="Produkt" jsonText="produkt" /></td>
                        </tr>
                        <tr>
                            <td>
                                <TextBoxExistingWo headerText="Kontaktperson" text={woUpdateData} setText={setWoUpdateData} jsonText="clientContact" maxLength="30" />
                            </td>
                            <td>
                                <TextBoxExistingWo headerText="Telefon" text={woUpdateData} setText={setWoUpdateData} jsonText="clientPhone"
                                    maxLength="13" isErr={isErr} errText="Obligatorisk, 0-9 och +" />
                            </td>
                        </tr>
                        <tr>
                            <td><LabelExistingWo text={fixDateFormat(woDetails[0].REQUIRED_START_DATE)} headerText="Önskad starttid" jsonText="" /></td>
                            <td><LabelExistingWo text={fixDateFormat(woDetails[0].REQUIRED_END_DATE)} headerText="Önskad sluttid" jsonText="" /></td>
                        </tr>
                        <tr>
                            <td><LabelExistingWo text={fixDateFormat(woDetails[0].REAL_S_DATE)} headerText="Verklig start" jsonText="" /></td>
                            <td><LabelExistingWo text={fixDateFormat(woDetails[0].REAL_F_DATE)} headerText="Verklig slut" jsonText="" /></td>
                        </tr>
                        <tr>
                            <td><LabelExistingWo text={fixDateFormat(woDetails[0].PLAN_S_DATE)} headerText="Planerad start" jsonText="" /></td>
                            <td><LabelExistingWo text={fixDateFormat(woDetails[0].PLAN_F_DATE)} headerText="Planerad slut" jsonText="" /></td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <LabelExistingWo text={getParameterValue("8134")} headerText="Order skapad av" jsonText="" />
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <button className="btn btnUpd" onClick={saveWo} disabled={isUpdating}>
                                    Spara
                                </button>
                                {" "}<div>{updateMsg.message}</div>
                                {
                                    isUpdating ? <img src={waitonCreate} alt="loading..." className="loadGifsmall" /> : <></>
                                }
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </>
        );
    } else {
        if (isCancel) {
            return (
                <>
                    <Table responsive="lg" className="table1" borderless="true">
                        <tbody>
                            <tr>
                                <td colSpan={2}>
                                    <FaEdit id="faediticon" data-tooltip-content="Ändra data" onClick={cancel} className="faEdit" />
                                    <Tooltip anchorId="faediticon" multiline={true} data-html={true} />
                                    <FaRegTrashAlt id="facancelicon" data-tooltip-content="Makulera ärende" onClick={cancel} className="faEdit" />
                                    <Tooltip anchorId="facancelicon" multiline={true} data-html={true} />
                                </td>
                            </tr>
                            <tr>
                                <td>Makulera ärende</td>
                                <td>{" "}</td>
                            </tr>
                            <tr>
                                <td colSpan="2">
                                    <textarea id="txtAreaAdd" value={cancelText} onChange={cancelReasonChange}
                                        name="cancelTxt" className="txtAreaExisting" rows="3" cols="100" ></textarea>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <button className="btn" onClick={saveCancelWo} disabled={cancelText.length === 0 ? true : false}>
                                        Spara
                                    </button>
                                </td>
                                <td>{" "}</td>
                            </tr>
                        </tbody>
                    </Table>
                </>
            );
        } else {
            return (
                <>
                    <Table responsive="lg" className="table1 align-items-center" borderless="true">
                        <tbody>
                            <tr>
                                <td colSpan={2}>
                                    <FaEdit id="faediticon" data-tooltip-content="Ändra data" onClick={edit} className="faEdit" />
                                    <Tooltip anchorId="faediticon" multiline={true} data-html={true} />{" "}
                                    <FaRegTrashAlt id="facancelicon" data-tooltip-content="Makulera ärende" onClick={cancel} className="faEdit" />
                                    <Tooltip anchorId="facancelicon" multiline={true} data-html={true} />
                                </td>
                            </tr>
                            <tr>
                                <td><LabelExistingWo text={fixDateFormat(woDetails[0].REG_DATE)} headerText="Skapad" jsonText="createdtime" /></td>
                                <td><LabelExistingWo text={woDetails[0].REFERENCE_NO} headerText="Referensnummer" jsonText="refnr" /></td>
                            </tr>
                            <tr>
                                <td><LabelExistingWo text={statusMappings(woDetails[0].OBJSTATE, getParameterValue("9090"), woDetails[0].KUND_MAK)}
                                    headerText="Status" jsonText="state" css={colorMappings(woDetails[0].OBJSTATE, getParameterValue("9090"), woDetails[0].KUND_MAK)} /></td>
                                <td>
                                    <LabelExistingWo text={woDetails[0].EXECUTE_BY !== "" ? woDetails[0].EXECUTE_BY : "Tekniker ej tillsatt"} headerText="Tekniker på jobbet" jsonText="executeBy" />
                                    <LabelExistingWo text={woDetails[0].EXECUTE_BY_MOBILE} headerText="" jsonText="executeByMobile" />
                                </td>
                            </tr>
                            <tr>
                                <td><LabelExistingWo text={woDetails[0].KOMMUN} headerText="Kommun" jsonText="kommun" /></td>
                                <td><LabelExistingWo text={getParameterValue("9801")} headerText="Produkt" jsonText="produkt" /></td>
                            </tr>
                            <tr>
                                <td><LabelExistingWo text={woDetails[0].CONTACT} headerText="Kontaktperson" jsonText="contactp" /></td>
                                <td><LabelExistingWo text={woDetails[0].PHONE_NO} headerText="Telefonnummer" jsonText="telcontact" /></td>
                            </tr>
                            <tr>
                                <td><LabelExistingWo text={fixDateFormat(woDetails[0].REQUIRED_START_DATE)} headerText="Önskad starttid" jsonText="starttim" /></td>
                                <td><LabelExistingWo text={fixDateFormat(woDetails[0].REQUIRED_END_DATE)} headerText="Önskad sluttid" jsonText="endtime" /></td>
                            </tr>
                            <tr>
                                <td><LabelExistingWo text={fixDateFormat(woDetails[0].REAL_S_DATE)} headerText="Verklig start" jsonText="" /></td>
                                <td><LabelExistingWo text={fixDateFormat(woDetails[0].REAL_F_DATE)} headerText="Verklig slut" jsonText="" /></td>
                            </tr>
                            <tr>
                                <td><LabelExistingWo text={fixDateFormat(woDetails[0].PLAN_S_DATE)} headerText="Planerad start" jsonText="" /></td>
                                <td><LabelExistingWo text={fixDateFormat(woDetails[0].PLAN_F_DATE)} headerText="Planerad slut" jsonText="" /></td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <LabelExistingWo text={getParameterValue("8134")} headerText="Order skapad av" jsonText="" />
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </>
            )
        }
    }
}

export default TabOverView