import "./existingWoComponent.css";
import { FaInfoCircle } from "react-icons/fa";
import { Tooltip } from 'react-tooltip'

const LabelExistingWo = ({ text, headerText, jsonText, toolTip = "", css = "" }) => {

    return (
        <>
            <h6 className="txtH6extWo">{headerText}{" "}
                <FaInfoCircle id={`info-circel-${jsonText}`} data-tooltip-content={toolTip} display={toolTip.length === 0 ? ("none") : ("")} />
                <Tooltip anchorId={`info-circel-${jsonText}`} multiline={true} data-html={true} />
            </h6>
            <div className={"divText"}>{css !== "" ? (<><div className={`statusCircle ${css}b`} />{text}</>) : (text)}</div>
        </>
    );
}

export default LabelExistingWo