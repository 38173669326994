import { useState, useEffect } from "react";
import loadinggif from "../../../assets/load.gif";
import { loginRequest } from '../../../authConfig';
import { useMsal } from '@azure/msal-react';
import Attachment from "./Attachment";
import AddAttachment from "../NewWO/AddAttachment";
import { FaEdit } from "react-icons/fa";
import { Tooltip } from 'react-tooltip';
import ShowAttachements from "../NewWO/ShowAttachements";
import { Button } from 'react-bootstrap';

const TabAttachments = ({ wono, triggerGetFiles, setTriggerGetFiles }) => {
    const ApiDomain = process.env.REACT_APP_BACKEND_API_HOST;
    const { instance, accounts } = useMsal();
    const [woAttachments, setWoAttachments] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [files, setFiles] = useState({});
    const [isEdit, setIsEdit] = useState(false);
    const [addingFiles, setAddingFiles] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    let antAttachments = 0;

    const edit = (e) => {
        setIsEdit(!isEdit);
    };

    const deleteFile = (file) => {
        const tmpFile = [];
        let i = 0;
        Array.from(files).forEach(f => {
            if (f.name !== file.name) {
                console.log(f.name);
                tmpFile[i] = f;
                i++;
            }
        });
        console.log(tmpFile);
        setFiles(tmpFile);
    };

    function addDocumentsToIFS() {
        antAttachments = 0;
        setAddingFiles(true);
        let i = 0;
        Array.from(files).forEach(file => {
            i += 1;
            console.log(`Call 1 ${i} uploadFiles: ${file}`);
            getInstance("FILE", file);
        });
        //setAddingFiles(false);
    }
    function getInstance(type, file = null) {
        console.log("getInstance type = " + type);
        instance
            .acquireTokenSilent({
                ...loginRequest,
                account: accounts[0],
            })
            .then((response) => {
                console.log(accounts[0]);
                if (type === "FILE") {
                    uploadFiles(file, response);
                }
            })
            .catch((error) => {
                instance
                    .acquireTokenPopup({
                        ...loginRequest,
                        account: accounts[0],
                    })
                    .then((response) => {
                        if (type === "FILE") {
                            uploadFiles(file, response);
                        }
                        console.log(error);
                    });
            });
    }

    async function uploadFiles(file, responseToken) {
        try {
            setAddingFiles(true)
            let msg = "Bilagor uppladdade.";
            console.log(file);
            console.log("uploadFiles wono = " + wono);
            if (wono.length !== 8) {
                return;
            }
            const headers = new Headers();
            const bearer = `Bearer ${responseToken.accessToken}`;
            headers.append("Authorization", bearer);
            var url = `https://${ApiDomain}/Ora/ora/Files/Uplod/singel?wono=${wono}`;
            console.log(`add attachments URL: ${url}`);
            let response = await fetchWithTimeout(
                url,
                {
                    headers: headers,
                    timeout: 300000,
                }, file
            );
            if (!response.ok) {
                console.log(`error 1 =  ${response.text}`);
            }
            const returnVal = await response.json();
            antAttachments += 1;
            if (returnVal.IsOk === false) {
                setErrorMessage(returnVal.Error)
                if (antAttachments === files.length) {
                    setAddingFiles(false);
                }
            } else {
                if (antAttachments === files.length) {
                    setAddingFiles(false);
                    setIsEdit(false);
                    setTriggerGetFiles(triggerGetFiles + 1);
                    setFiles({});
                }
            }
        } catch (error) {
            console.log(`error =  ${error}`);
            console.log(error.name === "AbortError");
        }
    }

    async function fetchWithTimeout(resource, options = {}, file) {
        const { timeout = 80000 } = options;

        const controller = new AbortController();
        const id = setTimeout(() => controller.abort(), timeout);

        const formData = new FormData();
        formData.append("fileUpload", file);

        const response = await fetch(resource, {
            ...options,
            signal: controller.signal,
            method: "POST",
            body: formData,
        });
        clearTimeout(id);
        return response;
    }

    useEffect(() => {
        const getInstance = async () => {
            setIsLoading(true);
            instance
                .acquireTokenSilent({
                    ...loginRequest,
                    account: accounts[0],
                })
                .then((response) => {
                    fetchAttachmentList(response);
                })
                .catch((error) => {
                    instance
                        .acquireTokenPopup({
                            ...loginRequest,
                            account: accounts[0],
                        })
                        .then((response) => {
                            fetchAttachmentList(response);
                            console.log(error);
                        });
                });
        }

        function fetchAttachmentList(response) {
            const headers = new Headers();
            const bearer = `Bearer ${response.accessToken}`;
            headers.append("Authorization", bearer);
            const options = {
                method: "GET",
                headers: headers
            };
            let url = "";
            url = `https://${ApiDomain}/ora/get/attachment/list?wono=${wono}`;
            fetch(url, options).then((response) => response.json()).then((data) => {
                console.log(data);
                setWoAttachments(data);
                setIsLoading(false);
            }
            ).catch(error => console.log(error));
        }

        if (triggerGetFiles)
        {
            getInstance();
        }
    }, [triggerGetFiles]);
    if (isEdit) {
        return (
            <div className="divFile">
                <FaEdit id="faediticonAttEdit" data-tooltip-content="Bilagor på arbetsorder." onClick={edit} className="faEdit" />
                <Tooltip anchorId="faediticonAttEdit" multiline={true} data-html={true} />
                <div className="div-container-attachment">
                    <div className="div-add-attachment">
                        <AddAttachment files={files} setFiles={setFiles} />
                    </div>
                    <div className="div-list-attachment">
                        {
                            Array.from(files).map((file, index) =>
                                <ShowAttachements file={file} key={index} deleteFile={deleteFile} />
                            )
                        }
                    </div>
                    <div>
                        <Button className="btn"
                            disabled={files.length === 0 || Array.isArray(files) === false ? true : false}
                            onClick={addDocumentsToIFS}>
                            Lägg till
                        </Button>
                        {
                            addingFiles ? (
                                <img src={loadinggif} alt="loading..." className="loadGif loadGiflist" />
                            ) : (<>{errorMessage}</>)
                        }
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div className="divFile">
                <FaEdit id="faediticonAtt" data-tooltip-content="Lägg till bilagor." onClick={edit} className="faEdit" />
                <Tooltip anchorId="faediticonAtt" multiline={true} data-html={true} />
                {isLoading ? (<img src={loadinggif} alt="loading..." className="loadGif loadGiflist" />) : (

                    woAttachments.length > 0 ? (
                        woAttachments.map((attachment, index) => (
                            <Attachment key={index} attachments={attachment} />
                        ))
                    ) : (
                        <p>Bilagor saknas.</p>
                    )

                )}

            </div>
        );
    }
}

export default TabAttachments