import ProgressBar from 'react-bootstrap/ProgressBar';
const Progressbar = ({ step, maxStep }) => {
  return (
    <div className="divBar">
      <ProgressBar className='progressBar' variant="progressBar" now={step} min={1} max={maxStep} />
      {/*<div className={`divBarPart ${step > 0 ? "divBarPartColor" : ""}`}></div>
      <div className={`divBarPart ${step > 1 ? "divBarPartColor" : ""}`}></div>
      <div className={`divBarPart ${step > 2 ? "divBarPartColor" : ""}`}></div>
      <div className={`divBarPart ${step > 3 ? "divBarPartColor" : ""}`}></div>
      <div className={`divBarPart ${step > 4 ? "divBarPartColor" : ""}`}></div>
      <div className={`divBarPart ${step > 5 ? "divBarPartColor" : ""}`}></div>
      <div className={`divBarPart ${step > 6 ? "divBarPartColor" : ""}`}></div>
  <div className={`divBarPart ${step > 7 ? "divBarPartColor" : ""}`}></div>*/}

    </div>
  );
};

export default Progressbar;
