import React from 'react'
import { FaTimes } from "react-icons/fa";

const ShowAttachements = ({ file, deleteFile }) => {
    const del = (e) => {
        deleteFile(file);
    };
    return (
        <><span>{file.name}</span><FaTimes id="fatime" className='imgTrash' title='Ta bort' onClick={del} /><br /></>
    )
}

export default ShowAttachements