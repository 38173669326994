import "./textT1.css";
import { useEffect, useState } from "react";
import { FaInfoCircle } from "react-icons/fa";
import { Tooltip } from 'react-tooltip'
import { formatDate } from "../Utils.js"

const TextBoxDateTime = ({ placeholder, setText, text, headerText, jsonText, clsname, sla = "", toolTip = "", disabled, errText = "", validateIsErr, isBack = false }) => {
    const [minTime, setMinTime] = useState("");
    const [minTimeNoSLA, setMinTimeNoSLA] = useState("");
    //const [txtCss, setTxtCss] = useState(`txtBox2 ${clsname}`);
    function handleChange(evt) {
        //if (isDateOk(evt.target.value)) {
        setText({ ...text, [evt.target.name]: evt.target.value })
        //}
    }

    useEffect(() => {
        const runOnce = async () => {
            var now = formatDate(new Date());
            if (jsonText === "startTime") {
                if (sla.min_start_time !== null) {
                    setMinTime(sla.min_start_time.replace(" ", "T"));
                } else if (sla.min_start_time === null && sla.min_end_time === null) {
                    setMinTimeNoSLA(now);
                    setMinTime("");
                }
            } else if (jsonText === "endTime") {
                if (sla.min_end_time !== null) {
                    setMinTime(sla.min_end_time.replace(" ", "T"));
                } else if (sla.min_start_time === null && sla.min_end_time === null) {
                    setMinTimeNoSLA(now);
                    setMinTime("");
                }
            }
            console.log(sla);
        }
        runOnce();
    }, [sla]);

    useEffect(() => {
        // Use updater function for setState to prevent timing/concurrency issues
        // https://stackoverflow.com/a/43639228/1767490
        if (isBack === false) {
            setText(prevState => ({ ...prevState, [jsonText]: minTime }));
        }
    }, [minTime]);

    function isDisabled() {
        let ret = false;
        if (minTime === "" && minTimeNoSLA === "" && (jsonText === "startTime" || jsonText === "endTime")) {
            ret = true;
        } else if (disabled) {
            ret = true;
        }
        else {
        }
        return ret;
    }
    return (
        <>
            <h6 className="txtH6">{headerText}{" "}
                <FaInfoCircle id={`info-circel-${jsonText}`} data-tooltip-html={toolTip} display={toolTip.length === 0 ? ("none") : ("")} />
                <Tooltip anchorId={`info-circel-${jsonText}`} multiline={true} data-html={true} className="infoCircelTop2" />
            </h6>
            <input type="datetime-local" placeholder={placeholder} className={validateIsErr[jsonText] === true ? "txtBox2 txtBox2_error" : "txtBox2"}
                value={text[jsonText].substring(0, 16)}
                onChange={handleChange} name={jsonText} id={jsonText}
                min={minTime !== "" ? minTime.substring(0, 16) : minTimeNoSLA}
                disabled={isDisabled()} /><br />
            <small className="errSmall">{validateIsErr[jsonText] === true ? errText : ""}</small>
        </>
    )
}

export default TextBoxDateTime