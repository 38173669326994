
import waitonCancel from "../../../assets/load.gif";

const WoCanceled = ({ woCanceledStatus, setCancelInProgress, updateWoList, exitWindow }) => {
    const exitWindowWoCan = async () => {
        updateWoList();
        setCancelInProgress(false);
        exitWindow();
    };

    return (
        <div className="divWoCanceled">
            {(woCanceledStatus === "OK") || (woCanceledStatus === "ERROR") ?
                (
                    woCanceledStatus === "OK" ? (
                        <>
                            <p>Arbetsorder makulerad.</p>
                        </>
                    ) : (
                        <p>Fel vid makulering av arbetsorder, försök igen eller kontakta SPOC.</p>
                    )
                ) : (
                    <><p>Order makuleras...</p><img src={waitonCancel} alt="loading..." className="loadGif" /></>
                )
            }

            {woCanceledStatus !== "PENDING" ? (
                <>
                    <br /><br />
                    <button className="btn" onClick={exitWindowWoCan}>Stäng</button>
                </>
            ) : (<></>)}
        </div>
    );
}

export default WoCanceled