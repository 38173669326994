
import waitonCreate from "../../../assets/load.gif";
const NewWoCreated = ({ wono, setLoading, setWono, fileStatus = 0, updateWoList, addingFiles, exitWindow, createWoError, setCreateWoError, createWO }) => {
    const exitWindowWoCre = async () => {
        //setWono("");
        updateWoList();
        setLoading(false);
        exitWindow();
    };

    const retryWoCre = async () => {
        setCreateWoError(false);
        setLoading(false);
        createWO();
    };

    const abortWoCre = async () => {
        setCreateWoError(false);
        setLoading(false);
    };

    return (
        <div className="divNewWOCreated">
            {createWoError === false ?
                (
                    wono.length > 2 ?
                        (
                            wono.length === 8 ? (
                                <>
                                    <p>Arbetsorder skapad {wono}</p>
                                    {addingFiles === true ? (
                                        <>
                                            <p>Filer läggs till, vänta...</p><img src={waitonCreate} alt="loading..." className="loadGif" />
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </>
                            ) : (
                                <p>Något gick fel: {wono}</p>
                            )
                        ) : (
                            <><p>Order skapas...</p><img src={waitonCreate} alt="loading..." className="loadGif" /></>
                        )
                ) : (<></>)
            }

            {createWoError === false ?
                (
                    <>
                        <br /><br />
                        <button className="btn" onClick={exitWindowWoCre} disabled={wono.length !== 8 || addingFiles === true}>
                            Stäng
                        </button>
                    </>
                ) :
                (
                    <div>
                        <p className="errorMessage">Arbetsorder gick inte att skapa, försök igen eller kontakta Eltel SPOC om problemet kvarstår.</p>
                        <br /><br />
                        <button className="btn" onClick={retryWoCre}>
                            Försök igen
                        </button>
                        <button className="btn" onClick={abortWoCre}>
                            Avbryt
                        </button>
                    </div>
                )}

        </div>
    );
}

export default NewWoCreated