import TextBoxT1 from "../controls/TextBoxT1";
import TextBoxDateTime from "../controls/TextBoxDateTime";
import { FaInfoCircle } from "react-icons/fa";
import { Tooltip } from 'react-tooltip'
import DropDownSearch from "../controls/DropDownSearch";
import DropDownSearchD2 from "../controls/DropDownSearchD2";

const Step1 = ({ setText, text, ddKommun, ddProdukt, remValKommun, setRemValKommun, remValLine, setRemValLine, sla, setSla, account, validateIsErr, isBack = false, setIsBack }) => {

  return (
    <>
      <div class="div-table">
        <div class="div-table-row">
          <div class="div-table-col-header">
            <h3 className="stepH3">
              Grundläggande information
              {" "}
              <FaInfoCircle id="ttStep1" className="infoCircelTop"
                data-tooltip-html="Alla fälten här är obligatoriska." />
              <Tooltip anchorId="ttStep1" className="infoCircelTop2" multiline={true} data-html={true} html />
            </h3>
          </div>
        </div>
        <div class="div-table-row">
          <div class="div-table-col">
            <DropDownSearch headerText="Kommun" ddData={ddKommun} setText={setText} text={text} jsonText="kobject" tooltipText="Här väljer du den kommun där jobbet ska utföras."
              remValKommun={remValKommun} setRemValKommun={setRemValKommun} sla={sla} setSla={setSla} errText="Måste ha ett värde." validateIsErr={validateIsErr} />
          </div>
          <div class="div-table-col">
            <DropDownSearchD2 headerText="Produkt" ddData={ddProdukt} setText={setText} text={text} jsonText="product" tooltipText="Här väljer du den produkt du vill beställa."
              remValLine={remValLine} setRemValLine={setRemValLine} sla={sla} setSla={setSla} errText="Måste ha ett värde." validateIsErr={validateIsErr} setIsBack={setIsBack} />
          </div>
        </div>
        <div class="div-table-row">
          <div class="div-table-col">
            <TextBoxT1 headerText="Kort beskrivning" jsonText="shortDesc" setText={setText} text={text}
              toolTip="Kort beskrivning för arbetsordern. Använd föreslagen eller skriv en egen." validateIsErr={validateIsErr} errText="Obligatorisk"
              minLength="5" maxLength="55" clsname="txtBox2_big" />
          </div>
        </div>
        <div class="div-table-row">
          <div class="div-table-col">
            <TextBoxDateTime headerText="Starttid" jsonText="startTime" setText={setText} text={text} sla={sla} errText="Fel datumformat eller intervall." validateIsErr={validateIsErr}
              toolTip="Här väljer du önskad tid för när Eltel ska påbörja jobbet i fält.<br />Obs! Vid avtalad SLA ska jobbet påbörjas vid denna tid."
              isBack={isBack} />
          </div>
          <div class="div-table-col">
            <TextBoxDateTime headerText="Sluttid" jsonText="endTime" setText={setText} text={text} sla={sla} errText="Fel datumformat eller intervall." validateIsErr={validateIsErr}
              toolTip="Här väljer du önskad klar tid för när Eltel ska slutföra jobbet i fält.<br />Obs vid avtalad SLA ska jobbet slutföras enligt avtalets direktiv."
              isBack={isBack} />
          </div>
        </div>
        <div class="div-table-row">
          <div class="div-table-col">
            <TextBoxT1 headerText="Referensnummer" jsonText="referensnummer" setText={setText} text={text}
              toolTip="Skriv in erat id för denna arbetsorder. Godkända tecken är bokstäver, siffror, komma och bindestreck." validateIsErr={validateIsErr}
              errText="Obligatorisk, endast bokstäver, siffror, bindestreck och komma." minLength="3" maxLength="20" />
          </div>
          <div class="div-table-col">
            <TextBoxT1 headerText="Kontaktperson beställare" jsonText="clientContact" setText={setText} text={text} validateIsErr={validateIsErr} maxLength="30" />
          </div>
        </div>
        <div class="div-table-row">
          <div class="div-table-col">
            <TextBoxT1 headerText="E-mail beställare" jsonText="clientContactEmail" placeholder="namn@foretag.se"
              setText={setText} text={text} validateIsErr={validateIsErr} maxLength="112" errText="Format ej korekt." />
          </div>
          <div class="div-table-col">
            <TextBoxT1 headerText="Telefon beställare" jsonText="clientPhone" placeholder="" setText={setText} text={text} validateIsErr={validateIsErr}
              maxLength="12" errText="Obligatorisk,+ och 0-9 är ok" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Step1;
