import { statusMappings, colorMappings, fixDateFormat } from "../../../components/pc/Utils.js"
const WorkOrderItem = ({ WOItem, ShowWoinfo }) => {
  return (
    <div className="divItem">
      <h3
        className="h3ShortDesc"
        onClick={() => {
          ShowWoinfo(WOItem);
        }}
      >
        {WOItem.ERR_DESCR}
      </h3>
      <div className="divList">
        <div className="labelList">{WOItem.WO_NO}&nbsp;|&nbsp;</div>
        <div className="labelList"> {fixDateFormat(WOItem.REG_DATE)}&nbsp;|&nbsp;</div>
        <div className={"labelList " + colorMappings(WOItem.OBJSTATE, WOItem.KUNDKLAR, WOItem.KUND_MAK)}>Status: {statusMappings(WOItem.OBJSTATE, WOItem.KUNDKLAR, WOItem.KUND_MAK)}&nbsp;</div>
        <div className="labelList">|&nbsp;Ref nr: {WOItem.REFERENCE_NO}</div>
      </div>
      <div className="divAddress">{WOItem.ADDRESS2}</div>
    </div>
  );
};

export default WorkOrderItem;
