import React from 'react'
import { FaInfoCircle } from "react-icons/fa";
import { Tooltip } from 'react-tooltip'

const TextArea = ({ PlaceHolder, setText, text, headerText, jsonText, clsname = "", tooltipText = "" }) => {
    function handleChange(evt) {
        setText({ ...text, [evt.target.name]: evt.target.value })
    }
    return (
        <>
            <h6 className="txtH6">{headerText}
                {" "}
                {tooltipText !== "" ? (
                    <>
                        <FaInfoCircle id={`info-circel-${jsonText}`} className="infoCircelTop" data-tooltip-html={tooltipText} />
                        <Tooltip anchorId={`info-circel-${jsonText}`} className="infoCircelTop2" multiline={true} data-html={true} html />
                    </>
                ) : (<></>)}
            </h6>
            <textarea value={text[jsonText]} onChange={handleChange} name={jsonText} className={`txtArea ${clsname}`} rows="8" maxLength={20000}>
            </textarea>
            <p className={"textAreaCounter" + (text[jsonText].length === 20000 ? " textAreaCounterMax" : "")}>
                {text[jsonText].length > 15000 ? (text[jsonText].length.toLocaleString('sv-SE') + " av 20 000") : ""}
            </p>
        </>
    );
}

export default TextArea