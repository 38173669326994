import "./existingWoComponent.css";
import { useState, useEffect } from "react";
import { FaInfoCircle } from "react-icons/fa";
import { Tooltip } from 'react-tooltip'

const TextBoxExistingWo = ({ placeholder, setText, text, headerText, jsonText, clsname, toolTip = "", disabled, maxLength, isErr = false, errText = "" }) => {
    const [minTime, setMinTime] = useState("");

    function handleChange(evt) {
        setText({ ...text, [evt.target.name]: evt.target.value })
    }

    function isDisabled() {
        let ret = false;
        if (minTime === "" && (jsonText === "startTime" || jsonText === "endTime")) {
            ret = true;
        } else if (disabled) {
            ret = true;
        }
        else {
        }
        return ret;
    }

    return (
        <>
            <h6 className="txtH6extWo">{headerText}{" "}
                <FaInfoCircle id={`info-circel-${jsonText}`} data-tooltip-content={toolTip} display={toolTip.length === 0 ? ("none") : ("")} />
                <Tooltip anchorId={`info-circel-${jsonText}`} multiline={true} data-html={true} />
            </h6>
            <input type="text" placeholder={placeholder} className={isErr === true ? "txtBoxComp txtBox2_error" : "txtBoxComp"} value={text[jsonText]}
                onChange={handleChange} name={jsonText} min={minTime} maxLength={maxLength}
                disabled={isDisabled()} /><br />
            <small className="errSmall">{isErr === true ? errText : ""}</small>
        </>
    );
}

export default TextBoxExistingWo