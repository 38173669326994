import "./buttonS1.css";
const ButtonS1 = ({ text, clickButton, pos = "" }) => {
  return (
    <>
      <button
        className={"button-primary-button-primary " + pos}
        onClick={clickButton}
      >
        <span className="button-primary-text CTAtext">
          <span>{text}</span>
        </span>
      </button>
    </>
  );
};

export default ButtonS1;
