
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../../authConfig';
import { useState, useEffect } from "react";
import SavedWorkOrder from './SavedWorkOrder';
import loadingGif from "../../../assets/load.gif";
import { Modal, Button, Card } from 'react-bootstrap';

const SavedWorkOrders = ({ closeSavedWorkOrders, setChosenWo }) => {
    const ApiDomain = process.env.REACT_APP_BACKEND_API_HOST;
    const [loading, setLoading] = useState(true);
    const [runGetAllSaved, setRunGetAllSaved] = useState(false);
    const [idToDelete, setIdToDelete] = useState(-1);
    const [savedWorkOrders, setSavedWorkOrders] = useState([]);
    const { instance, accounts } = useMsal();

    useEffect(() => {
        function deleteGetInstance(type, id) {
            instance
                .acquireTokenSilent({
                    ...loginRequest,
                    account: accounts[0],
                })
                .then((response) => {
                    console.log(accounts[0]);
                    if (type === "DELETE") {
                        deleteSavedWorkOrders(response, id);
                    }
                })
                .catch((error) => {
                    instance
                        .acquireTokenPopup({
                            ...loginRequest,
                            account: accounts[0],
                        })
                        .then((response) => {
                            if (type === "DELETE") {
                                deleteSavedWorkOrders(response, id);
                            }
                            console.log(error);
                        });
                });
        }

        function deleteSavedWorkOrders(response, id) {
            const headers = new Headers();
            const bearer = `Bearer ${response.accessToken}`;
            headers.append("Authorization", bearer);
            const options = {
                method: "DELETE",
                headers: headers
            };
            let url = `https://${ApiDomain}/UserSaveWorkOrder/delete/${id}`;
            fetch(url, options).then(function (response) {
                if (response.ok) {
                    setRunGetAllSaved(!runGetAllSaved);
                } else {
                    console.log("Fel vid borttagning av wo.");
                }
            }).catch((error) => {
                console.log(error);
            });
        }
        if (idToDelete !== -1) {
            deleteGetInstance("DELETE", idToDelete);
            setIdToDelete(-1);
        }
    }, [idToDelete]);

    useEffect(() => {
        function getInstance(type) {
            //setLoading(true);
            console.log("getInstance type = " + type);
            instance
                .acquireTokenSilent({
                    ...loginRequest,
                    account: accounts[0],
                })
                .then((response) => {
                    console.log(accounts[0]);
                    if (type === "SAVEDWO") {
                        getSavedWorkOrders(response);
                    }
                })
                .catch((error) => {
                    instance
                        .acquireTokenPopup({
                            ...loginRequest,
                            account: accounts[0],
                        })
                        .then((response) => {
                            if (type === "SAVEDWO") {
                                getSavedWorkOrders(response);
                            }
                            console.log(error);
                        });
                });
        }

        function getSavedWorkOrders(response) {
            const headers = new Headers();
            const bearer = `Bearer ${response.accessToken}`;
            headers.append("Authorization", bearer);
            const options = {
                method: "GET",
                headers: headers
            };
            let url = `https://${ApiDomain}/UserSaveWorkOrder/get/all`;
            fetch(url, options).then(function (response) {
                if (!response.ok) {
                    console.log("Fel vid hämtning av sparade wos.");
                }
                return response.json();
            }).then((data) => {
                console.log(data);
                setSavedWorkOrders(data);
                setLoading(false);
            }
            ).catch((error) => {
                console.log(error);
                setLoading(false);
            });

        }
        getInstance("SAVEDWO");
    }, [runGetAllSaved]);

    const displayColorInformation = (savedWorkOrders) => {
        const oldWorkOrders = savedWorkOrders.filter(wo => {
            let dateAdded = new Date(wo.DateAdd);
            let difference = new Date().getTime() - dateAdded.getTime();
            let totalDays = Math.ceil(difference / (1000 * 3600 * 24));

            return totalDays > 120; // 120 = 4 x 30 days = 4 months
        });

        return oldWorkOrders.length > 0;
    }

    return (
        <Modal show={true} size="lg" onHide={closeSavedWorkOrders === 0 ? true : false} fullscreen={"sm-down"} className="modalDialogNewWo" style={{ zIndex: 1200 }}>
            <Modal.Header className="modal-header divHeader">
                <Modal.Title><h3 className='h3savedWo'>Dina sparade arbetsordrar.</h3></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Card bg="transparent" className="border-0 align-items-center" >
                    <Card.Body>
                        <Card.Text>
                            <div>Dina sparade ordrar sparas endast i 6 månader därefter tas dessa bort.</div>
                        </Card.Text>
                    </Card.Body>
                </Card>
                <div class="div-table">
                    {
                        loading === false ? (

                            savedWorkOrders.length !== 0 ? (
                                <>
                                    <div class="div-table-col-header">
                                        <div class="div-table-col div-table-col-name"><h3 className="stepH3">Namn på order</h3></div>
                                        <div class="div-table-col div-table-col-date"><h3 className="stepH3">Sparad datum</h3></div>
                                    </div>
                                    {
                                        savedWorkOrders.map((savedWo, index) => (
                                            <SavedWorkOrder key={index} savedWo={savedWo} setChosenWo={setChosenWo} closeSavedWorkOrders={closeSavedWorkOrders}
                                                setIdToDelete={setIdToDelete} />
                                        ))
                                    }
                                </>
                            ) : (
                                <p>Inga sparade arbetsordrar hittades.</p>
                            )
                        ) : (
                            <img src={loadingGif} alt="loading..." className="loadGif loadGiflist" />
                        )
                    }
                </div>
                <Card bg="transparent" className={displayColorInformation(savedWorkOrders) == true ? 'visible' : 'invisible'}>
                    <Card.Body>
                        <Card.Text>
                            <div><div className={`statusCircle color1b`} style={{ float: 'left', marginTop: '7px' }} />&nbsp;=&nbsp;Sparad order kommer tas bort 6 månader efter log datum.</div>
                            <div><div className={`statusCircle color7b`} style={{ float: 'left', marginTop: '7px' }} />&nbsp;=&nbsp;Sparad order kommer tas bort inom 1 månad.</div>
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => closeSavedWorkOrders(0)}>
                    Stäng
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default SavedWorkOrders