import "./radioButton.css";
import { FaInfoCircle } from "react-icons/fa";
import { Tooltip } from 'react-tooltip'

const RadioButtonR1 = ({ setText, text, headerText, jsonText, toolTip = "", validateIsErr, errText }) => {
    function handleChange(evt) {
        setText({ ...text, [evt.target.name]: evt.target.value })
    }
    return (
        <div className="divRadio">
            <label className="radioLabel">{headerText}{" "}
                <FaInfoCircle id={`info-circel-${jsonText}`} data-tooltip-content={toolTip} display={toolTip.length === 0 ? ("none") : ("")} />
                <Tooltip anchorId={`info-circel-${jsonText}`} multiline={true} data-html={true} />
            </label>
            <input type="radio" id="yes" name={jsonText} value="Yes" checked={text[jsonText] === "Yes"} onChange={handleChange} />
            <label htmlFor="yes" className="radioLabel">Ja</label>
            <input type="radio" id="no" name={jsonText} value="No" checked={text[jsonText] === "No"} onChange={handleChange} />
            <label htmlFor="no" className="radioLabel">Nej</label>
            <small className="errSmall">{validateIsErr[jsonText] === true ? errText : ""}</small>
        </div>
    )
}

export default RadioButtonR1