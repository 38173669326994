import "./textBoxSearch.css";
const TextBoxSearch = ({ searchText, setSearchText, handleKeyDown }) => {
  function handleChange(evt) {
    setSearchText(evt.target.value);
  }
  return (
    <><input type="search" className="txtBox" placeholder="Sök" value={searchText} onChange={handleChange} onKeyDown={handleKeyDown} /></>
  )
}

export default TextBoxSearch